import { ActionType } from '../../action/settings/bonuses';

const initialState = {
  bonuses: {},
};

const setBonuses = (state, data) => ({
  ...state,
  bonuses: data,
});

const Bonuses = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_BONUSES:
      return setBonuses(state, action.payload);

    default:
      return state;
  }
};

export default Bonuses;
