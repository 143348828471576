import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Button, Form, message, Space,
} from 'antd';
import PropTypes from 'prop-types';

import ModalWrapper from '../modal-wrapper/modal-wrapper';

import styles from './modal-form-wrapper.module.scss';

const ModalFormWrapper = ({
  title,
  visibility,
  initialValues,
  initialExtraAction,
  validationSchema,
  onSubmit,
  onCancel,
  successMessage,
  errorMessage,
  errorExtraActions,
  submitButtonTitle,
  onValuesChange,
  children,
}) => {
  const [isSending, setIsSending] = useState(false);

  const [form] = Form.useForm();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setIsSending(true);
      onSubmit(values)
        .then(() => {
          setIsSending(false);
          message.success(successMessage);
          formik.resetForm();
          form.resetFields();
        })
        .catch((err) => {
          setIsSending(false);

          if (errorExtraActions) {
            errorExtraActions(err);
          } else if (
            err
            && err.response
            && err.response.data
            && err.response.data.detail
          ) {
            message.error(err.response.data.detail);
          } else if (
            err
            && err.response
            && err.response.data
            && err.response.data.non_field_errors
            && err.response.data.non_field_errors[0]
          ) {
            message.error(err.response.data.non_field_errors[0]);
          } else {
            message.error(errorMessage);
          }
        });
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (initialExtraAction) {
      initialExtraAction(formik, form);
    }
  }, []);

  return (
    <ModalWrapper title={title} open={visibility} onCancel={handleCancel}>
      {(setIsFormFieldsChanged, getCancelFunction) => (
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            ...formik.values,
          }}
          onFinish={formik.handleSubmit}
          className={styles['modal-form-wrapper__form']}
          onValuesChange={onValuesChange}
          onFieldsChange={() => setIsFormFieldsChanged(true)}
        >
          {children(formik, form, isSending)}
          <Form.Item className={styles['modal-form-wrapper__form-controls']}>
            <Space>
              <Button
                // disabled={!formik.isValid || isSending}
                disabled={isSending}
                type="primary"
                htmlType="submit"
              >
                {submitButtonTitle || 'Создать'}
              </Button>
              <Button
                disabled={isSending}
                type="default"
                onClick={getCancelFunction()}
              >
                Отмена
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </ModalWrapper>
  );
};

ModalFormWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  visibility: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape(),
  ]).isRequired,
  initialValues: PropTypes.shape().isRequired,
  initialExtraAction: PropTypes.func,
  validationSchema: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  successMessage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  errorExtraActions: PropTypes.func,
  submitButtonTitle: PropTypes.string,
  onValuesChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
};

ModalFormWrapper.defaultProps = {
  initialExtraAction: null,
  submitButtonTitle: null,
  errorExtraActions: null,
  onValuesChange: null,
};

export default ModalFormWrapper;
