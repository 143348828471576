import { ReadOutlined } from '@ant-design/icons';

import styles from './instruction.module.scss';

const Instruction = () => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href="https://invoice.su/instruction"
    className={styles.instruction__link}
  >
    <ReadOutlined className={`d-sm-none ${styles.instruction__icon}`} />
    <span className="d-sm-block">Инструкция</span>
  </a>
);

export default Instruction;
