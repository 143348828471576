import ReactInputMask from 'react-input-mask';

import { codeNumberFormatter } from '../../../formatters';

const getPsbSbpConnectionFields = () => [
  {
    label: 'ID организации (MERCHANT из Excel файла)',
    name: 'merchant',
    placeholder: 'Введите идентификационный номер',
    component: ReactInputMask,
    mask: '999999999999999',
    maskChar: 'X',
    formatter: codeNumberFormatter,
  },
  {
    label: 'Наименование организации (MERCHANT_NAME из Excel файла)',
    name: 'merchantName',
    placeholder: 'Введите наименование организации',
  },
  {
    label: 'Терминал (TERMINAL из Excel файла)',
    name: 'terminal',
    placeholder: 'Введите id терминала',
    component: ReactInputMask,
    mask: '99999999',
    maskChar: 'X',
    formatter: codeNumberFormatter,
  },
  {
    label: 'МСС (MCC из Excel файла)',
    name: 'mcc',
    placeholder: 'Введите МСС',
    component: ReactInputMask,
    mask: '9999',
    maskChar: 'X',
    formatter: codeNumberFormatter,
  },
  {
    label: 'МАС1',
    name: 'comp1',
    placeholder: 'Введите МАС1',
  },
  {
    label: 'МАС2',
    name: 'comp2',
    placeholder: 'Введите МАС2',
  },
];

export default getPsbSbpConnectionFields;
