export const ActionType = {
  SET_COMPANIES: 'SET_COMPANIES',
  PUSH_COMPANY: 'PUSH_COMPANY',
  SET_COMPANY: 'SET_COMPANY',
  SET_USER: 'SET_USER',
  SET_PATH_BEFORE_AUTH: 'SET_PATH_BEFORE_AUTH',
  SET_IS_INITIAL_DATA_LOADING: 'SET_IS_INITIAL_DATA_LOADING',
};

export const ActionCreator = {
  setCompanies: (data) => ({
    type: ActionType.SET_COMPANIES,
    payload: data,
  }),

  pushCompany: (data) => ({
    type: ActionType.PUSH_COMPANY,
    payload: data,
  }),

  setCompany: (data) => ({
    type: ActionType.SET_COMPANY,
    payload: data,
  }),

  setUser: (data) => ({
    type: ActionType.SET_USER,
    payload: data,
  }),

  setPathBeforeAuth: (path) => ({
    type: ActionType.SET_PATH_BEFORE_AUTH,
    payload: path,
  }),

  setIsInitialDataLoading: (data) => ({
    type: ActionType.SET_IS_INITIAL_DATA_LOADING,
    payload: data,
  }),
};
