import { ActionType } from '../../action/payouts/payouts';

import { parseDateTimeFormat } from '../../../utils/helpers/shared';

const initialState = {
  banks: [],
  payouts: {},
  payoutsConnections: [],
  widgets: {},
  bankLimits: {},
  innerLimits: [],
  registries: {},
  businessCards: {},
  createInfo: [],
  merchantGoods: {},
  payoutBasisSettings: {},
  balances: {},
};

const setBanks = (state, data) => ({
  ...state,
  banks: data,
});

const setPayouts = (state, data) => ({
  ...state,
  payouts: data,
});

const setPayoutsConnections = (state, data) => ({
  ...state,
  payoutsConnections: data,
});

const setWidgets = (state, data) => ({
  ...state,
  widgets: data,
});

const setBankLimits = (state, data) => ({
  ...state,
  bankLimits: data,
});

const setInnerLimits = (state, data) => ({
  ...state,
  innerLimits: data,
});

const setRegistries = (state, data) => ({
  ...state,
  registries: data,
});

const setBusinessCards = (state, data) => ({
  ...state,
  businessCards: data,
});

const setCreateInfo = (state, data) => ({
  ...state,
  createInfo: data,
});

const setMerchantGoods = (state, data) => ({
  ...state,
  merchantGoods: data,
});

const setPayoutBasisSettings = (state, data) => ({
  ...state,
  payoutBasisSettings: data,
});

const setBalances = (state, data) => ({
  ...state,
  balances: {
    balances: data.balances,
    updatedAt: parseDateTimeFormat(),
  },
});

const payouts = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_BANKS:
      return setBanks(state, action.payload);

    case ActionType.SET_PAYOUTS:
      return setPayouts(state, action.payload);

    case ActionType.SET_PAYOUTS_CONNECTIONS:
      return setPayoutsConnections(state, action.payload);

    case ActionType.SET_WIDGETS:
      return setWidgets(state, action.payload);

    case ActionType.SET_BANK_LIMITS:
      return setBankLimits(state, action.payload);

    case ActionType.SET_INNER_LIMITS:
      return setInnerLimits(state, action.payload);

    case ActionType.SET_REGISTRIES:
      return setRegistries(state, action.payload);

    case ActionType.SET_BUSINESS_CARDS:
      return setBusinessCards(state, action.payload);

    case ActionType.SET_CREATE_INFO:
      return setCreateInfo(state, action.payload);

    case ActionType.SET_MERCHANT_GOODS:
      return setMerchantGoods(state, action.payload);

    case ActionType.SET_PAYOUT_BASIS_SETTINGS:
      return setPayoutBasisSettings(state, action.payload);

    case ActionType.SET_BALANCES:
      return setBalances(state, action.payload);

    default:
      return state;
  }
};

export default payouts;
