import { ActionType } from '../../action/customers/customers';

const initialState = {
  segments: [],
  customers: [],
};

const setSegments = (state, data) => ({
  ...state,
  segments: data,
});

const setCustomers = (state, data) => ({
  ...state,
  customers: data,
});

const customers = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_SEGMENTS:
      return setSegments(state, action.payload);

    case ActionType.SET_CUSTOMERS:
      return setCustomers(state, action.payload);

    default:
      return state;
  }
};

export default customers;
