import { ActionType } from '../../action/dashboard/dashboard';

const initialState = {
  widgets: {},
  chartProfitData: [],
  buys: [],
  refunds: [],
  feed: [],
};

const setWidgets = (state, data) => ({
  ...state,
  widgets: data,
});

const setChartProfit = (state, data) => ({
  ...state,
  chartProfitData: data,
});

const setBuys = (state, data) => ({
  ...state,
  buys: data,
});

const setRefunds = (state, data) => ({
  ...state,
  refunds: data,
});

const setFeed = (state, data) => ({
  ...state,
  feed: data,
});

const resetData = (state) => ({
  ...state,
  buys: [],
  refunds: [],
  feed: [],
});

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_WIDGETS:
      return setWidgets(state, action.payload);

    case ActionType.SET_CHART_PROFIT:
      return setChartProfit(state, action.payload);

    case ActionType.SET_BUYS:
      return setBuys(state, action.payload);

    case ActionType.SET_REFUNDS:
      return setRefunds(state, action.payload);

    case ActionType.SET_FEED:
      return setFeed(state, action.payload);

    case ActionType.RESET_DATA:
      return resetData(state);

    default:
      return state;
  }
};

export default dashboard;
