import { Tooltip } from 'antd';

const BigNumberTooltip = ({ transformPrice, price, unit }) => {
  if (unit) {
    return (
      <Tooltip title={transformPrice}>
        {`${price.slice(0, -unit.value)} ${unit.title}`}
      </Tooltip>
    );
  }

  return transformPrice;
};

export default BigNumberTooltip;
