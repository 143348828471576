const adaptDateTimeFormat = (momentObject) => momentObject
  .utcOffset(180, true)
  .set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  .format();

export default adaptDateTimeFormat;
