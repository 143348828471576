export const ActionType = {
  SET_POINTS_OF_SALE: 'SET_POINTS_OF_SALE',
};

export const ActionCreator = {
  setPointsOfSale: (data) => ({
    type: ActionType.SET_POINTS_OF_SALE,
    payload: data,
  }),
};
