const adaptRequestBody = (values) => {
  const requestBody = { ...values };

  delete requestBody.logoUrl;

  requestBody.images.forEach((item) => {
    delete item.pictureUrl;
  });

  Object.keys(requestBody).forEach((key) => {
    if (requestBody[key] === '') {
      requestBody[key] = null;
    }
  });

  return requestBody;
};

export default adaptRequestBody;
