export const ActionType = {
  SET_REPORTS: 'SET_REPORTS',
};

export const ActionCreator = {
  setReports: (data) => ({
    type: ActionType.SET_REPORTS,
    payload: data,
  }),
};
