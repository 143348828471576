const avaiablePayMethods = [
  {
    id: 0,
    name: 'SBP',
    icon: 'https://hb.bizmrg.com/invoice-media/payment_methods/sbp-pay.svg',
  },
  {
    id: 1,
    name: 'Google Pay',
    icon: 'https://hb.bizmrg.com/invoice-media/payment_methods/google-pay.svg',
  },
  {
    id: 3,
    name: 'Apple Pay',
    icon: 'https://hb.bizmrg.com/invoice-media/payment_methods/apple-pay.svg',
  },
  {
    id: 4,
    name: 'Samsung Pay',
    icon: 'https://hb.bizmrg.com/invoice-media/payment_methods/samsung-pay.svg',
  },
  {
    id: 2,
    name: 'Card',
    icon: 'https://hb.bizmrg.com/invoice-media/payment_methods/card-pay.svg',
  },
];

export default avaiablePayMethods;
