import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Cookies from 'js-cookie';
import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import ModalAddCompany from './modal-add-company/modal-add-company';

import styles from './company.module.scss';

const Company = ({ toggleCollapsed }) => {
  const router = useRouter();
  const companies = useSelector((state) => state.user.companies);
  const company = useSelector((state) => state.user.company);

  const [addCompanyOpen, setAddCompanyOpen] = useState(false);

  const toggleAddCompanyOpen = () => setAddCompanyOpen(!addCompanyOpen);

  useEffect(() => {
    if (window.jivo_api) {
      window.jivo_api.setCustomData([
        {
          title: 'Авторизован',
          content: 'Да',
        },
        {
          title: 'ИНН',
          content: company.inn,
        },
      ]);
      window.jivo_api.setContactInfo({
        name: company.name,
        email: company.email,
        phone: company.phone,
      });
    }
  }, [company]);

  const changeCompany = (e) => {
    localStorage.setItem('id', e.key);
    Cookies.remove('payoutTerminal');
    router.reload();
  };

  const menu = (
    <div className={styles.company__dropdown}>
      <Menu
        className={`${styles.company__menu} ${styles['company__menu--scroll']}`}
      >
        {companies.map((item) => (
          <Menu.Item
            key={item.id}
            icon={(
              <Image
                src={
                  item.logo
                    ? item.logo
                    : 'https://hb.bizmrg.com/invoice-media/default/merchant_logo.png'
                }
                alt="Логотип компании"
                width={24}
                height={24}
              />
            )}
            className={styles.company__item}
            onClick={(e) => changeCompany(e)}
          >
            <p>{item.name}</p>
            <p>{item.legalName}</p>
          </Menu.Item>
        ))}
      </Menu>
      <Menu className={styles.company__menu}>
        <Menu.Item
          key="new"
          className={`${styles.company__item} ${styles['company__item--new']}`}
          onClick={() => {
            if (toggleCollapsed) {
              toggleCollapsed();
            }
            toggleAddCompanyOpen();
          }}
        >
          Добавить организацию
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <div className={styles.company}>
      {/* Logo */}
      <div className={styles.company__logo}>
        {company.dirlogo ? (
          <Image src={company.dirlogo} alt="Логотип" width="40" height="40" />
        ) : null}
      </div>

      {/* Info */}
      <Dropdown overlay={menu} trigger={['click']}>
        <button type="button" className="ant-dropdown-link btn-link">
          <div className={styles.company__wrap}>
            <div>
              <p className={styles.company__name}>{company.name}</p>
              <p className={styles.company__legaly}>{company.legalName}</p>
              <p className={styles.company__inn}>{company.inn}</p>
            </div>
            <CaretDownOutlined />
          </div>
        </button>
      </Dropdown>

      <ModalAddCompany
        isOpen={addCompanyOpen}
        onSuccess={toggleAddCompanyOpen}
        onCancel={toggleAddCompanyOpen}
      />
    </div>
  );
};

Company.propTypes = {
  toggleCollapsed: PropTypes.func,
};

Company.defaultProps = {
  toggleCollapsed: null,
};

export default Company;
