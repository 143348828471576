import { cloneDeep } from 'lodash';

const getNestedObjectFieldValue = (basisObject, pathParts) => {
  let value = Array.isArray(basisObject)
    ? basisObject.slice()
    : cloneDeep(basisObject);

  pathParts.forEach((element) => {
    value = value[element] || '';
  });

  return value;
};

export default getNestedObjectFieldValue;
