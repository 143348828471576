export const ActionType = {
  SET_PAY_METHODS: 'SET_PAY_METHODS',
  SET_TERMINALS: 'SET_TERMINALS',
  SET_CURRENT_PAYMENT_INFO: 'SET_CURRENT_PAYMENT_INFO',
};

export const ActionCreator = {
  setPayMethods: (data) => ({
    type: ActionType.SET_PAY_METHODS,
    payload: data,
  }),

  setTerminals: (data) => ({
    type: ActionType.SET_TERMINALS,
    payload: data,
  }),

  setCurrentPaymentInfo: (data) => ({
    type: ActionType.SET_CURRENT_PAYMENT_INFO,
    payload: data,
  }),
};
