export const ActionType = {
  SET_WIDGETS: 'dashboard/loadWidget',
  SET_CHART_PROFIT: 'dashboard/loadChartProfit',
  SET_BUYS: 'dashboard/setBuys',
  SET_REFUNDS: 'dashboard/setRefunds',
  SET_FEED: 'dashboard/setFeed',
  RESET_DATA: 'dashboard/resetData',
};

export const ActionCreator = {
  setWidgets: (data) => ({
    type: ActionType.SET_WIDGETS,
    payload: data,
  }),

  setChartProfit: (data) => ({
    type: ActionType.SET_CHART_PROFIT,
    payload: data,
  }),

  setBuys: (data) => ({
    type: ActionType.SET_BUYS,
    payload: data,
  }),

  setRefunds: (data) => ({
    type: ActionType.SET_REFUNDS,
    payload: data,
  }),

  setFeed: (data) => ({
    type: ActionType.SET_FEED,
    payload: data,
  }),

  resetData: () => ({
    type: ActionType.RESET_DATA,
  }),
};
