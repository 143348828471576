import adaptPhoneNumber from './_adapt-phone-number';

const adaptRequestBodyPhoneField = (body, field = 'phone') => {
  const requestBody = { ...body, [field]: adaptPhoneNumber(body[field]) };

  Object.keys(requestBody).forEach((key) => {
    if (requestBody[key] === '') {
      delete (requestBody[key]);
    }
  });

  return requestBody;
};

export default adaptRequestBodyPhoneField;
