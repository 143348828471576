import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { FormField, ModalFormWrapper } from '../../../shared';

import { changeName } from '../../../../services/settings/general';
import { loadUser } from '../../../../services/user';

// import styles from './modal-change-name.module.scss';

const ModalChangeName = ({
  changeNameModalVisible,
  setChangeNameModalVisible,
}) => {
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      name: user.name || '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(150, 'Имя должно содержать не более 150 символов')
      .required('Поле обязательно для заполнения'),
  });

  const onSubmit = (values) => dispatch(changeName(values))
    .then(() => {
      dispatch(loadUser());
      setChangeNameModalVisible(!changeNameModalVisible);
    });

  const onCancel = () => {
    setChangeNameModalVisible(!changeNameModalVisible);
  };

  return (
    <ModalFormWrapper
      title="Смена имени"
      visibility={changeNameModalVisible}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage="Имя пользователя изменено"
      errorMessage="Произошла ошибка"
      submitButtonTitle="Сохранить"
    >
      {(formik) => (
        <FormField
          formik={formik}
          label="Введите новое имя"
          name="name"
          placeholder="Введите имя"
          prefix={<UserOutlined />}
        />
      )}
    </ModalFormWrapper>
  );
};

ModalChangeName.propTypes = {
  changeNameModalVisible: PropTypes.bool.isRequired,
  setChangeNameModalVisible: PropTypes.func.isRequired,
};

export default ModalChangeName;
