import { WarningTwoTone } from '@ant-design/icons';
import styles from './error.module.scss';

const Error = () => (
  <div className={styles.error}>
    <WarningTwoTone twoToneColor="#D62A0D" />
    &nbsp;&nbsp;Данные не&nbsp;были загружены
  </div>
);

export default Error;
