import { combineReducers } from 'redux';
import stat from './stat/stat';
import authorization from './authorization/authorization';
import bonus from './bonus/bonus';
import connection from './connection/connection';
import customer from './customer/customer';
import customers from './customers/customers';
import dashboard from './dashboard/dashboard';
import goods from './goods/goods';
import terminals from './terminals/terminals';
import user from './user/user';
import general from './settings/general';
import profile from './settings/profile';
import employers from './settings/employers';
import notifications from './settings/notifications';
import bonuses from './settings/bonuses';
import payments from './reports/payments';
import refunds from './reports/refunds';
import reports from './reports/reports';
import pointsOfSale from './points-of-sale/points-of-sale';
import payouts from './payouts/payouts';

// COMBINED REDUCERS
const reducers = {
  stat,
  authorization,
  bonus,
  connection,
  customer,
  customers,
  dashboard,
  goods,
  terminals,
  user,
  general,
  profile,
  employers,
  notifications,
  bonuses,
  payments,
  refunds,
  reports,
  pointsOfSale,
  payouts,
};

export default combineReducers(reducers);
