import BigNumberTooltip from '../../../components/shared/big-number-tooltip/big-number-tooltip';
import transformNumbers from './_transform-numbers';

const convertBigNumbers = (data) => {
  if (data === 0) {
    return data;
  }

  const price = Number.prototype.toFixed.call(parseFloat(data));
  const transformPrice = transformNumbers(data, 2);

  const getUnit = () => {
    if (price.length >= 10) {
      return { title: 'млрд', value: 9 };
    }

    if (price.length >= 7) {
      return { title: 'млн', value: 6 };
    }

    return null;
  };

  return (
    <BigNumberTooltip
      transformPrice={transformPrice}
      price={price}
      unit={getUnit()}
    />
  );
};

export default convertBigNumbers;
