export const ActionType = {
  SET_CUSTOMER_INFO: 'SET_CUSTOMER_INFO',
  SET_CUSTOMER_BUYS: 'SET_CUSTOMER_BUYS',
};

export const ActionCreator = {
  setCustomerInfo: (data) => ({
    type: ActionType.SET_CUSTOMER_INFO,
    payload: data,
  }),

  setCustomerBuys: (data) => ({
    type: ActionType.SET_CUSTOMER_BUYS,
    payload: data,
  }),
};
