import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Menu } from 'antd';
import PropTypes from 'prop-types';

import { LinkTo } from '../../shared';

import { ActionCreator } from '../../../store/action/authorization/authorization';

import { PAGES } from '../../../utils/constants/shared';

import styles from './nav.module.scss';

const Nav = ({ toggleCollapsed }) => {
  const company = useSelector((state) => state.user.company);
  const bonuses = useSelector((state) => state.bonuses.bonuses);

  const [activeItem, setActiveItem] = useState(false);

  const dispatch = useDispatch();

  const router = useRouter();

  const handleExitButtonClick = () => {
    dispatch(ActionCreator.resetStore());
    dispatch(ActionCreator.logout());
  };

  const getMenuItem = (title, route, icon) => (
    <Menu.Item
      icon={icon}
      key={route}
      className={
        activeItem === route
          ? `${styles.menu__item} ${styles['menu__item--active']}`
          : styles.menu__item
      }
    >
      <LinkTo
        href={route}
        onClick={() => {
          setActiveItem(route);

          if (title === PAGES.exit.title) {
            handleExitButtonClick();
          }
          if (toggleCollapsed) {
            toggleCollapsed();
          }
        }}
      >
        {title}
      </LinkTo>
    </Menu.Item>
  );

  useEffect(() => {
    setActiveItem(router.pathname);
  }, []);

  return (
    <Menu selectedKeys={router.pathname} className={styles.menu}>
      {Object.keys(PAGES).map((key) => {
        const {
          title, route, icon, companyTypes,
        } = PAGES[key];
        let menuItem;

        if (
          companyTypes
          && !companyTypes.includes(company.type)
          && route !== PAGES.exit.route
        ) {
          return null;
        }

        if (icon && route !== PAGES.bonus.route) {
          menuItem = getMenuItem(title, route, icon);
        } else if (route === PAGES.bonus.route && bonuses?.enabled) {
          menuItem = getMenuItem(title, route, icon);
        } else {
          menuItem = null;
        }

        return menuItem;
      })}
    </Menu>
  );
};

Nav.propTypes = {
  toggleCollapsed: PropTypes.func,
};

Nav.defaultProps = {
  toggleCollapsed: null,
};

export default Nav;
