import { forwardRef } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { getNestedObjectFieldValue } from '../../../utils/helpers/shared';

import styles from './form-field.module.scss';

const FormField = forwardRef(({
  formik,
  name,
  label,
  onChange,
  value,
  component: Component,
  children,
  formItemClassName,
  hint,
  formikNestedName,
  hidden,
  ...props
}, ref) => {
  const errorStatus = () => getNestedObjectFieldValue(formik.errors, formikNestedName);

  const getHelpValue = () => {
    if (formikNestedName) {
      return (
        (formik.touched[name]
          || getNestedObjectFieldValue(formik.touched, formikNestedName))
        && (errorStatus() || null)
      );
    }

    return formik.touched[name] && formik.errors[name]
      ? formik.errors[name]
      : null;
  };

  const getValidateStatus = () => {
    if (formikNestedName) {
      return (formik.touched[name]
        || getNestedObjectFieldValue(formik.touched, formikNestedName))
        && errorStatus()
        ? 'error'
        : 'success';
    }

    return formik.touched[name] && formik.errors[name] ? 'error' : 'success';
  };

  return (
    <Form.Item
      label={
        hint ? (
          <div className={styles['form-field__wrapper']}>
            {label}
            <Tooltip
              title={hint}
              trigger={['click', 'hover']}
              className={styles['form-field__tooltip']}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ) : (
          label
        )
      }
      name={name}
      help={getHelpValue()}
      validateStatus={getValidateStatus()}
      className={formItemClassName}
      hidden={hidden}
    >
      <Component
        onChange={onChange || formik.handleChange}
        onBlur={formik.handleBlur}
        value={value || formik.values[name]}
        {...props}
        ref={ref}
      >
        {children}
      </Component>
    </Form.Item>
  );
});

FormField.propTypes = {
  formik: PropTypes.shape({
    touched: PropTypes.shape({}).isRequired,
    errors: PropTypes.shape({}).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.shape({}).isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.element,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  component: PropTypes.elementType,
  formItemClassName: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  hint: PropTypes.string,
  formikNestedName: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  hidden: PropTypes.bool,
};

FormField.defaultProps = {
  component: Input,
  children: null,
  label: null,
  value: null,
  onChange: null,
  formItemClassName: null,
  hint: null,
  formikNestedName: null,
  hidden: false,
};

export default FormField;
