export const ActionType = {
  SET_SEGMENTS: 'SET_SEGMENTS',
  SET_CUSTOMERS: 'SET_CUSTOMERS',
};

export const ActionCreator = {
  setSegments: (data) => ({
    type: ActionType.SET_SEGMENTS,
    payload: data,
  }),

  setCustomers: (data) => ({
    type: ActionType.SET_CUSTOMERS,
    payload: data,
  }),
};
