import { ActionType } from '../../action/settings/general';

const initialState = {
  common: {},
  accounts: [],
  sbpStatus: {},
  sbpConnectionStatus: {},
  okveds: [],
  preActivate2FAInfo: {},
  status2FA: null,
  codes2FA: [],
};

const setCommon = (state, data) => ({
  ...state,
  common: data,
});

const setAccounts = (state, data) => ({
  ...state,
  accounts: data,
});

const setActivateAccount = (state, data) => ({
  ...state,
  accounts: data,
});

const addAccount = (state, data) => ({
  ...state,
  accounts: [...state.accounts, data],
});

const setSbpStatus = (state, data) => ({
  ...state,
  sbpStatus: data,
});

const setSbpConnectionStatus = (state, data) => ({
  ...state,
  sbpConnectionStatus: data,
});

const setOkveds = (state, data) => ({
  ...state,
  okveds: data,
});

const setPreActivate2FAInfo = (state, data) => ({
  ...state,
  preActivate2FAInfo: data,
});

const set2FAStatus = (state, data) => ({
  ...state,
  status2FA: data.useTwoFactorAuth,
});

const set2FACodes = (state, data) => ({
  ...state,
  codes2FA: data,
});

const general = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_COMMON:
      return setCommon(state, action.payload);

    case ActionType.SET_ACCOUNTS:
      return setAccounts(state, action.payload);

    case ActionType.SET_ACTIVE_ACCOUNT:
      return setActivateAccount(state, action.payload);

    case ActionType.ADD_ACCOUNT:
      return addAccount(state, action.payload);

    case ActionType.SET_SBP_STATUS:
      return setSbpStatus(state, action.payload);

    case ActionType.SET_SBP_CONNECTION_STATUS:
      return setSbpConnectionStatus(state, action.payload);

    case ActionType.SET_OKVEDS:
      return setOkveds(state, action.payload);

    case ActionType.SET_PRE_ACTIVATE_2FA_INFO:
      return setPreActivate2FAInfo(state, action.payload);

    case ActionType.SET_2FA_STATUS:
      return set2FAStatus(state, action.payload);

    case ActionType.SET_2FA_CODES:
      return set2FACodes(state, action.payload);

    default:
      return state;
  }
};

export default general;
