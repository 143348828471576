import { ActionType } from '../../action/reports/reports';

const initialState = {
  reports: {},
};

const setReports = (state, data) => ({
  ...state,
  reports: data,
});

const reports = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_REPORTS:
      return setReports(state, action.payload);

    default:
      return state;
  }
};

export default reports;
