import { ActionType } from '../../action/reports/refunds';

const initialState = {
  refunds: {},
};

const setRefunds = (state, data) => ({
  ...state,
  refunds: data,
});

const refunds = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_REFUNDS:
      return setRefunds(state, action.payload);

    default:
      return state;
  }
};

export default refunds;
