import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Button, Input, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import ReactInputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { FormField, ModalFormWrapper } from '../../../shared';

import {
  activate2FA,
  create2FACodes,
  loadPreActivate2FAInfo,
} from '../../../../services/settings/general';
import { loadUser } from '../../../../services/user';

import { maskedNumberFieldFormatter } from '../../../../utils/formatters';

import { modal2FAApplications } from '../../../../utils/constants/shared';

import styles from './modal-activate-2fa.module.scss';

const ModalActivate2FA = ({
  activate2FAModalVisible,
  setActivate2FAModalVisible,
  setCodes2FAModalVisible,
}) => {
  const [applicationsVisible, setApplicationsVisible] = useState(false);

  const preActivate2FAInfo = useSelector(
    (state) => state.general.preActivate2FAInfo,
  );

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      password: '',
      code: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    password: Yup.string().required('Поле обязательно для заполнения'),
    code: Yup.string()
      .length(6, 'Введите корректный код')
      .required('Поле обязательно для заполнения'),
  });

  useEffect(() => {
    dispatch(loadPreActivate2FAInfo()).catch(() => {
      message.error('Не удалось загрузить информацию для активации');
    });
  }, []);

  const onSubmit = (values) => dispatch(activate2FA(values)).then(() => {
    setActivate2FAModalVisible(!activate2FAModalVisible);
    dispatch(loadUser());
    dispatch(create2FACodes({ password: values.password })).then(() => {
      setCodes2FAModalVisible(2);
    });
  });

  const onCancel = () => {
    setActivate2FAModalVisible(!activate2FAModalVisible);
  };

  const handleApplicationsButtonClick = () => {
    setApplicationsVisible(!applicationsVisible);
  };

  return (
    <ModalFormWrapper
      title="Двухфакторная аутентификация"
      visibility={activate2FAModalVisible}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage="Двухфакторная аутентификация активирована"
      errorMessage="Произошла ошибка"
      submitButtonTitle="Активировать"
    >
      {(formik) => (
        <>
          <h3 className={styles['activate-2fa__title']}>
            Зарегистрируйте средство проверки подлинности одноразового пароля
          </h3>
          <p className={styles['activate-2fa__note']}>
            Используйте средство проверки подлинности с одноразовым паролем на
            вашем мобильном устройстве или компьютере, чтобы включить
            двухфакторную аутентификацию (2FA).
          </p>
          <p className={styles['activate-2fa__note']}>
            Мы рекомендуем использовать облачные приложения для проверки
            подлинности, которые могут восстановить доступ в случае потери
            вашего аппаратного устройства.
          </p>
          <Button
            type="link"
            onClick={handleApplicationsButtonClick}
            className={styles['activate-2fa__button']}
          >
            Примеры приложений
          </Button>
          {applicationsVisible && (
            <div className={styles['activate-2fa__applications']}>
              {modal2FAApplications.map((item) => (
                <>
                  <p>{item.title}</p>
                  <ul>
                    {item.applications.map((app) => (
                      <li>
                        <a
                          href={app.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {app.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              ))}
            </div>
          )}
          <div className={styles['activate-2fa__image-wrapper']}>
            {preActivate2FAInfo && preActivate2FAInfo.qrUrl && (
            <QRCode
              size={200}
              value={preActivate2FAInfo.qrUrl}
            />
            )}
          </div>
          <div className={styles['activate-2fa__info']}>
            <h3 className={styles['activate-2fa__info-title']}>
              Не удается отсканировать код?
            </h3>
            <p className={styles['activate-2fa__info-note']}>
              Чтобы добавить запись вручную, укажите следующие данные в
              приложении на вашем телефоне.
            </p>
            <p className={styles['activate-2fa__info-note']}>
              Учетная запись:&nbsp;
              {preActivate2FAInfo.account}
            </p>
            <p className={styles['activate-2fa__info-note']}>
              Ключ:&nbsp;
              {preActivate2FAInfo.key}
            </p>
            <p className={styles['activate-2fa__info-note']}>
              В зависимости от времени:&nbsp;
              {preActivate2FAInfo.timeCounter ? 'Да' : 'Нет'}
            </p>
          </div>
          <FormField
            formik={formik}
            label="Текущий пароль"
            name="password"
            placeholder="Введите текущий пароль"
            component={Input.Password}
            prefix={<LockOutlined />}
          />
          <p className={styles['activate-2fa__note']}>
            Ваш текущий пароль необходим для регистрации приложения с
            двухфакторной аутентификацией.
          </p>
          <FormField
            formik={formik}
            label="Проверочный код"
            name="code"
            placeholder="Введите проверочный код"
            component={ReactInputMask}
            mask="999999"
            maskChar="✱"
            onChange={(evt) => formik.setFieldValue(
              'code',
              maskedNumberFieldFormatter(evt.target.value),
            )}
            prefix={<UserOutlined />}
          >
            {(inputProps) => <Input {...inputProps} />}
          </FormField>
        </>
      )}
    </ModalFormWrapper>
  );
};

ModalActivate2FA.propTypes = {
  activate2FAModalVisible: PropTypes.bool.isRequired,
  setActivate2FAModalVisible: PropTypes.func.isRequired,
  setCodes2FAModalVisible: PropTypes.func.isRequired,
};

export default ModalActivate2FA;
