export const ActionType = {
  SET_EMPLOYERS: 'SET_EMPLOYERS',
  SET_EMPLOYER_DETAILS: 'SET_EMPLOYER_DETAILS',
  SET_ROLES: 'SET_ROLES',
  SET_AVAILABLE_TREE: 'SET_AVAILABLE_TREE',
};

export const ActionCreator = {
  setEmployers: (data) => ({
    type: ActionType.SET_EMPLOYERS,
    payload: data,
  }),

  setEmployerDetails: (data) => ({
    type: ActionType.SET_EMPLOYER_DETAILS,
    payload: data,
  }),

  setRoles: (data) => ({
    type: ActionType.SET_ROLES,
    payload: data,
  }),

  setAvailableTree: (data) => ({
    type: ActionType.SET_AVAILABLE_TREE,
    payload: data,
  }),
};
