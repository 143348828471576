import humps from 'humps';
import { ActionCreator } from '../../store/action/settings/profile';
import { adaptRequestBody } from '../../utils/helpers/settings/profile';
import { URL_UPLOADER } from '../../utils/constants/shared';
import { adaptRequestBodyPhoneField } from '../../utils/helpers/shared';

export const loadProfile = () => (dispatch, _getState, api) => api
  .post('/settings/profile')
  .then(({ data }) => {
    dispatch(ActionCreator.setProfile(humps.camelizeKeys(data)));
  });

export const updateProfile = (profileData) => (dispatch, _getState, api) => api
  .post(
    '/settings/profile',
    humps.decamelizeKeys(adaptRequestBody(profileData)),
  )
  .then(({ data }) => {
    dispatch(ActionCreator.updateProfile(humps.camelizeKeys(data)));
  });

export const loadOkveds = () => (dispatch, _getState, api) => api
  .post('/settings/okveds')
  .then(({ data }) => {
    dispatch(ActionCreator.setOkveds(humps.camelizeKeys(data)));
  });

export const uploadLogo = (binaryImg, type) => (dispatch, _getState, api) => {
  api.defaults.headers.put['Content-Type'] = type;

  return api.put(`${URL_UPLOADER}/profile/image?public=1`, binaryImg);
};

export const createPoint = (body) => (dispatch, _getState, api) => api.post(
  '/points-of-sale/add',
  humps.decamelizeKeys(adaptRequestBodyPhoneField(body)),
);

export const updatePoint = (body) => (dispatch, _getState, api) => api.post(
  '/points-of-sale/update',
  humps.decamelizeKeys(adaptRequestBodyPhoneField(body)),
);

export const deletePoint = (body) => (dispatch, _getState, api) => api.post(
  '/points-of-sale/delete',
  humps.decamelizeKeys(body),
);
