export const ActionType = {
  SET_CONNECTION_LIST: 'SET_CONNECTION_LIST',
};

export const ActionCreator = {
  setConnectionList: (data) => ({
    type: ActionType.SET_CONNECTION_LIST,
    payload: data,
  }),
};
