import { ActionType } from '../../action/terminals/terminals';

const initialState = {
  payMethods: [],
  terminals: [],
  currentPaymentInfo: null,
};

const setPayMethods = (state, data) => ({
  ...state,
  payMethods: data,
});

const setTerminals = (state, data) => ({
  ...state,
  terminals: data,
});

const setCurrentPaymentInfo = (state, data) => ({
  ...state,
  currentPaymentInfo: data,
});

const terminals = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_PAY_METHODS:
      return setPayMethods(state, action.payload);

    case ActionType.SET_TERMINALS:
      return setTerminals(state, action.payload);

    case ActionType.SET_CURRENT_PAYMENT_INFO:
      return setCurrentPaymentInfo(state, action.payload);

    default:
      return state;
  }
};

export default terminals;
