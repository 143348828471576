import { useState } from 'react';
import {
  Button, Drawer, Row, Space,
} from 'antd';
import { MailOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import MailingModal from './modals/mailing-modal/mailing-modal';
import Sidebar from '../sidebar/sidebar';
import User from './user/user';
import Instruction from './instruction/instruction';

import { PAGES } from '../../utils/constants/shared';

import styles from './header.module.scss';

const Header = ({ pageTitle }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [addMailingModalVisible, setAddMailingModalVisible] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <Row className={styles.header} align="middle">
        <div className={styles.header__menu}>
          <Button type="text" size="large" onClick={toggleCollapsed}>
            <MenuUnfoldOutlined />
          </Button>
        </div>

        <Drawer
          title="Invoice ЛК"
          placement="left"
          closable
          onClose={toggleCollapsed}
          open={collapsed}
          width={260}
          className={styles.header__drawer}
        >
          <Sidebar toggleCollapsed={toggleCollapsed} />
        </Drawer>

        <h1 className={styles.header__title}>{pageTitle}</h1>

        <Space className={styles.header__info}>
          {(pageTitle === PAGES.customers.title) && (
            <Button
              type="link"
              icon={<MailOutlined />}
              onClick={() => setAddMailingModalVisible(!addMailingModalVisible)}
            >
              <span className={styles['header__mailing-button-text']}>Рассылка</span>
            </Button>
          )}
          <User />
          <Instruction />
        </Space>
      </Row>

      {addMailingModalVisible && (
        <MailingModal
          addMailingModalVisible={addMailingModalVisible}
          setAddMailingModalVisible={setAddMailingModalVisible}
        />
      )}
    </>
  );
};

Header.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};

export default Header;
