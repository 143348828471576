import humps from 'humps';

import { ActionCreator } from '../store/action/points-of-sale/points-of-sale';

export const loadPointsOfSale = (body) => (dispatch, _getState, api) => api
  .post('/points-of-sale/list', humps.decamelizeKeys(body))
  .then(({ data }) => {
    dispatch(ActionCreator.setPointsOfSale(humps.camelizeKeys(data)));
    return data;
  });
