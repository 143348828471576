import { ActionType } from '../../action/bonus/bonus';

const initialState = {
  activity: [],
  balance: {},
  customersTop: [],
  profit: {},
  summary: {},
};

const setActivity = (state, data) => ({
  ...state,
  activity: data,
});

const setBalance = (state, data) => ({
  ...state,
  balance: data,
});

const setCustomersTop = (state, data) => ({
  ...state,
  customersTop: data,
});

const setProfit = (state, data) => ({
  ...state,
  profit: data,
});

const setSummary = (state, data) => ({
  ...state,
  summary: data,
});

const bonus = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_ACTIVITY:
      return setActivity(state, action.payload);

    case ActionType.SET_BALANCE:
      return setBalance(state, action.payload);

    case ActionType.SET_CUSTOMERS_TOP:
      return setCustomersTop(state, action.payload);

    case ActionType.SET_PROFIT:
      return setProfit(state, action.payload);

    case ActionType.SET_SUMMARY:
      return setSummary(state, action.payload);

    default:
      return state;
  }
};

export default bonus;
