import { message, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { uploadLogo } from '../../../services/settings/profile';
import styles from './upload-list-images.module.scss';

const UploadListImages = ({ formik, name }) => {
  const [fileList, setFileList] = useState([]);
  const images = formik.values[name];

  const dispatch = useDispatch();

  useEffect(() => {
    if (images.length !== 0) {
      setFileList(
        images.map((el) => ({
          url: el.pictureUrl,
        })),
      );
    }
  }, []);

  // Получает изображение в binary
  const getBinary = useCallback((img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsArrayBuffer(img);
  }, []);

  // Проверяет тип загружаемого файла
  const beforeUpload = (file) => {
    const isImage = file.type.slice(0, 5).toLowerCase() === 'image';

    if (!isImage) {
      message.error('Вы можете загрузить только изображение');
    }

    file.status = 'error';

    return isImage;
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  // Удаляет изображение
  const onRemove = (currentImg) => {
    const newUrlList = images.filter(
      (value) => value.pictureUrl !== currentImg.url,
    );
    formik.setFieldValue(name, newUrlList);
    setFileList((prev) => prev.filter((value) => value.uid !== currentImg.uid));
  };

  const handleUploader = ({ file, fileList: newFileList }) => {
    if (file.status === 'error') {
      message.error(`${file.name} не загружен.`);
    }

    if (file.status === 'done') {
      getBinary(file.originFileObj, async (url) => {
        // Запрос на загрузку изображения
        await dispatch(uploadLogo(url, file.type))
          .then(({ data }) => {
            message.success(`${file.name} загружен.`);
            formik.setFieldValue(name, [
              ...formik.values[name],
              { picture: data.filename, pictureUrl: data.url },
            ]);
          })
          .catch(() => {
            file.status = 'error';
            message.error(`${file.name} не загружен.`);
          });
      });
    }

    setFileList(newFileList);
  };

  return (
    <Upload
      accept="image/*"
      onChange={handleUploader}
      onRemove={onRemove}
      beforeUpload={beforeUpload}
      onPreview={onPreview}
      listType="picture-card"
      fileList={fileList}
      className={styles['upload-list-images__wrapper']}
    >
      <div>
        <PlusOutlined />
        <div className={styles['upload-list-images__placeholder']}>
          Загрузить
        </div>
      </div>
    </Upload>
  );
};

UploadListImages.propTypes = {
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
};

export default UploadListImages;
