export const ActionType = {
  SET_BANKS: 'SET_BANKS',
  SET_PAYOUTS: 'SET_PAYOUTS',
  SET_PAYOUTS_CONNECTIONS: 'SET_PAYOUTS_CONNECTIONS',
  SET_WIDGETS: 'SET_WIDGETS',
  SET_BANK_LIMITS: 'SET_BANK_LIMITS',
  SET_INNER_LIMITS: 'SET_INNER_LIMITS',
  SET_REGISTRIES: 'SET_REGISTRIES',
  SET_BUSINESS_CARDS: 'SET_BUSINESS_CARDS',
  SET_CREATE_INFO: 'SET_CREATE_INFO',
  SET_MERCHANT_GOODS: 'SET_MERCHANT_GOODS',
  SET_PAYOUT_BASIS_SETTINGS: 'SET_PAYOUT_BASIS_SETTINGS',
  SET_BALANCES: 'SET_BALANCES',
};

export const ActionCreator = {
  setBanks: (data) => ({
    type: ActionType.SET_BANKS,
    payload: data,
  }),
  setPayouts: (data) => ({
    type: ActionType.SET_PAYOUTS,
    payload: data,
  }),
  setPayoutsConnections: (data) => ({
    type: ActionType.SET_PAYOUTS_CONNECTIONS,
    payload: data,
  }),
  setWidgets: (data) => ({
    type: ActionType.SET_WIDGETS,
    payload: data,
  }),
  setBankLimits: (data) => ({
    type: ActionType.SET_BANK_LIMITS,
    payload: data,
  }),
  setInnerLimits: (data) => ({
    type: ActionType.SET_INNER_LIMITS,
    payload: data,
  }),
  setRegistries: (data) => ({
    type: ActionType.SET_REGISTRIES,
    payload: data,
  }),
  setBusinessCards: (data) => ({
    type: ActionType.SET_BUSINESS_CARDS,
    payload: data,
  }),
  setCreateInfo: (data) => ({
    type: ActionType.SET_CREATE_INFO,
    payload: data,
  }),
  setMerchantGoods: (data) => ({
    type: ActionType.SET_MERCHANT_GOODS,
    payload: data,
  }),
  setPayoutBasisSettings: (data) => ({
    type: ActionType.SET_PAYOUT_BASIS_SETTINGS,
    payload: data,
  }),
  setBalances: (data) => ({
    type: ActionType.SET_BALANCES,
    payload: data,
  }),
};
