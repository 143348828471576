export const ActionType = {
  SET_BONUSES: 'SET_BONUSES',
};

export const ActionCreator = {
  setBonuses: (data) => ({
    type: ActionType.SET_BONUSES,
    payload: data,
  }),
};
