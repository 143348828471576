import humps from 'humps';
import { ActionCreator } from '../../store/action/settings/bonuses';

export const loadBonuses = () => (dispatch, _getState, api) => api
  .post('/settings/bonuses')
  .then(({ data }) => {
    dispatch(ActionCreator.setBonuses(humps.camelizeKeys(data)));

    return humps.camelizeKeys(data);
  });

export const updateBonuses = (body) => (dispatch, _getState, api) => api.post('/settings/bonuses', humps.decamelizeKeys(body));
