import axios from 'axios';
import Cookies from 'js-cookie';
import router from 'next/router';
import { PAGES, REQUEST_TIMEOUT, URL_BASE } from '../utils/constants/shared';

const api = axios.create({
  baseURL: URL_BASE,
  timeout: REQUEST_TIMEOUT,
});

api.interceptors.request.use((config) => {
  const excludedPaths = ['/registration/send_code', '/registration/user', '/password_reset', '/password_reset/validate_token', '/password_reset/confirm'];

  if (!excludedPaths.includes(config.url)) {
    config.headers.Authorization = `Bearer ${Cookies.get('token')}`;
  }

  config.headers['Company-Id'] = localStorage.getItem('id');

  return config;
});

api.interceptors.response.use((config) => config,
  // Если получили ошибку при запросе
  async (error) => {
    const originalRequest = error.config;

    // Проверяем статус ошибки
    if (error.response && error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;

      try {
        const refresh = Cookies.get('refresh');

        if (refresh) {
          // Отправляем запрос на обновление токена(сервер будет смотреть на refresh токен, т.к. access токен истек)
          const response = await axios.post(`${URL_BASE}/auth/refresh`, { refresh });
          // Записываем новый полученный токен в куки
          Cookies.set('token', response.data.access);
          // Отправляем повторный запрос с обновленным токеном

          return api.request(originalRequest);
        }
      } catch (e) {
        // Если при refresh ошибка значит нужно заново пройти авторизацию, перенаправляем на /logout
        await router.push(PAGES.logout.route);
      }
    }

    throw error;
  });

export default api;
