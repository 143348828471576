import Image from 'next/image';
import { Affix } from 'antd';
import PropTypes from 'prop-types';

import Nav from './nav/nav';
import Company from './company/company';

import styles from './sidebar.module.scss';

const Sidebar = ({ toggleCollapsed }) => (
  <Affix offsetTop={20} className={styles.sidebar}>
    <div className={styles.sidebar__wrap}>

      <Company toggleCollapsed={toggleCollapsed} />

      <Nav toggleCollapsed={toggleCollapsed} />

      {/* Logo invoice */}
      <div className={styles['sidebar__image-wrapper']}>
        <Image
          src="/img/icon/logo.svg"
          alt="Invoice"
          width={75}
          height={30}
        />
      </div>
    </div>
  </Affix>
);

Sidebar.propTypes = {
  toggleCollapsed: PropTypes.func,
};

Sidebar.defaultProps = {
  toggleCollapsed: null,
};

export default Sidebar;
