export const ActionType = {
  SET_REFUNDS: 'SET_REFUNDS',
};

export const ActionCreator = {
  setRefunds: (data) => ({
    type: ActionType.SET_REFUNDS,
    payload: data,
  }),
};
