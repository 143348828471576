import Link from 'next/link';
import PropTypes from 'prop-types';

const LinkTo = ({
  children, href, as, replace, scroll, shallow, prefetch, locale, ...props
}) => (
  <Link {...{
    href, as, replace, scroll, shallow, prefetch, locale,
  }}
  >
    <a {...props}>{children}</a>
  </Link>
);

LinkTo.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  href: PropTypes.string,
  as: PropTypes.string,
  replace: PropTypes.bool,
  scroll: PropTypes.bool,
  shallow: PropTypes.bool,
  prefetch: PropTypes.bool,
  locale: PropTypes.string,
};

LinkTo.defaultProps = {
  children: null,
  href: null,
  as: null,
  replace: null,
  scroll: null,
  shallow: null,
  prefetch: null,
  locale: null,
};

export default LinkTo;
