import { useState } from 'react';
import { Modal, Space } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';

import styles from './modal-wrapper.module.scss';

const ModalWrapper = ({
  children, onCancel, isPreseted, ...otherProps
}) => {
  const [isFormFieldsChanged, setIsFormFieldsChanged] = useState(isPreseted);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);

  const handleModalDialogCloseAction = () => {
    setIsConfirmDialogVisible(true);
  };

  const handleConfirmDialogSuccessButtonClick = () => {
    setIsFormFieldsChanged(false);
    setIsConfirmDialogVisible(false);
    onCancel();
  };

  const handleConfirmDialogCancelButtonClick = () => {
    setIsConfirmDialogVisible(false);
  };

  const getCancelFunction = () => (isFormFieldsChanged ? handleModalDialogCloseAction : onCancel);

  return (
    <>
      <Modal
        onCancel={getCancelFunction()}
        destroyOnClose
        footer={null}
        {...otherProps}
      >
        {children(setIsFormFieldsChanged, getCancelFunction)}
      </Modal>
      {isConfirmDialogVisible && (
        <Modal
          title="Подтвердите действие"
          open={isConfirmDialogVisible}
          centered
          destroyOnClose
          onOk={handleConfirmDialogSuccessButtonClick}
          onCancel={handleConfirmDialogCancelButtonClick}
          zIndex={10000}
        >
          <Space align="center" size="middle">
            <WarningTwoTone className={styles['modal-wrapper__icon']} />
            <div>
              <p className={styles['modal-wrapper__text']}>
                Вы уверены, что хотите закрыть данное окно?
              </p>
              <p
                className={`${styles['modal-wrapper__text']} ${styles['modal-wrapper__text--warning']}`}
              >
                Внесенные данные будут утеряны.
              </p>
            </div>
          </Space>
        </Modal>
      )}
    </>
  );
};

ModalWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  onCancel: PropTypes.func.isRequired,
  isPreseted: PropTypes.bool,
};

ModalWrapper.defaultProps = {
  isPreseted: false,
};

export default ModalWrapper;
