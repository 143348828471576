export const ActionType = {
  LOGIN: 'authorization/login',
  LOGOUT: 'authorization/logout',
  CHECK_AUTH: 'authorization/checkAuth',
  SET_SESSION_TOKEN: 'authorization/setSessionToken',
  RESET: 'RESET',
};

export const ActionCreator = {
  login: (data) => ({
    type: ActionType.LOGIN,
    payload: data,
  }),
  logout: () => ({ type: ActionType.LOGOUT }),
  checkAuth: () => ({ type: ActionType.CHECK_AUTH }),
  setSessionToken: (data) => ({
    type: ActionType.SET_SESSION_TOKEN,
    payload: data,
  }),
  resetStore: () => ({ type: ActionType.RESET }),
};
