import { ActionType } from '../../action/settings/notifications';

const initialState = {
  notifications: [],
  availableTree: [],
  availableTelegrams: [],
  newTelegram: {},
};

const setNotifications = (state, data) => ({
  ...state,
  notifications: data,
});

const setAvailableTree = (state, data) => ({
  ...state,
  availableTree: data,
});

const setAvailableTelegrams = (state, data) => ({
  ...state,
  availableTelegrams: data,
});

const setNewTelegram = (state, data) => ({
  ...state,
  newTelegram: data,
});

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_NOTIFICATIONS:
      return setNotifications(state, action.payload);

    case ActionType.SET_AVAILABLE_TREE:
      return setAvailableTree(state, action.payload);

    case ActionType.SET_AVAILABLE_TELEGRAMS:
      return setAvailableTelegrams(state, action.payload);

    case ActionType.SET_NEW_TELEGRAM:
      return setNewTelegram(state, action.payload);

    default:
      return state;
  }
};

export default notifications;
