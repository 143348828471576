import adaptRfbRequestBody from './_adapt-rfb-request-body';
import adaptRosbankRequestBody from './_adapt-rosbank-request-body';
import adaptRequestBodyWithParams from './_adapt-request-body-with-params';
import adaptSkbRequestBody from './_adapt-skb-request-body';
import adaptTochkaRequestBody from './_adapt-tochka-request-body';

const adaptRequestBody = (values, adapterData) => {
  const getRequestBody = () => {
    switch (adapterData.bank) {
      case 'FTC':
        return values;

      case 'OPEN_BANK':
        return adaptRequestBodyWithParams(values);

      case 'PSB':
        return adaptRequestBodyWithParams(values);

      case 'RFB':
        return adaptRfbRequestBody(values);

      case 'ROSBANK':
        return adaptRosbankRequestBody(values);

      case 'SKB':
        return adaptSkbRequestBody(values, adapterData.personsQuantity);

      case 'TOCHKA':
        return adaptTochkaRequestBody(values);

      default:
        return values;
    }
  };

  const requestBody = getRequestBody();

  Object.keys(requestBody).forEach((key) => {
    if (requestBody[key] === null || requestBody[key] === '') {
      delete requestBody[key];
    }
  });

  return requestBody;
};

export default adaptRequestBody;
