import moment from 'moment';

import { adaptPhoneNumber } from '../../shared';

import { DATE_FORMAT } from '../../../constants/shared';

const adaptSkbRequestBody = (body, personsQuantity) => {
  const requestBody = {};

  const {
    merchantFullName,
    merchantAddressBossCity,
    merchantAddressBossGuid,
    merchantServiceRko,
    merchantServiceTradePointName,
    merchantServiceTradePointIsOnlineStore,
    merchantServiceTradePointMainUrl,
    merchantServiceTradePointMcc,
    merchantServiceTradePointOkved,
    merchantServiceTradePointPerson,
    merchantServiceTradePointPersonPhone,
    merchantServiceTradePointEmail,
    merchantServiceTradePointAddressArea,
    merchantServiceTradePointAddressCity,
    merchantServiceTradePointAddressZip,
    merchantServiceTradePointAddressStreet,
    merchantServiceTradePointAddressHouse,
  } = body;

  const getPersonsData = () => {
    const data = [];

    for (let i = 1; i <= personsQuantity; i++) {
      data.push({
        surname: body[`merchantPersonsDataPerson${i}Surname`],
        name: body[`merchantPersonsDataPerson${i}Name`],
        patronymicName: body[`merchantPersonsDataPerson${i}PatronymicName`],
        gender: body[`merchantPersonsDataPerson${i}Gender`],
        persontype: body[`merchantPersonsDataPerson${i}PersonType`],
        phone: adaptPhoneNumber(body[`merchantPersonsDataPerson${i}Phone`]),
        email: body[`merchantPersonsDataPerson${i}Email`],
        birthCity: body[`merchantPersonsDataPerson${i}BirthCity`],
        birthDate: moment(
          body[`merchantPersonsDataPerson${i}BirthDate`],
        ).format(DATE_FORMAT),
        document: {
          documentType:
            body[`merchantPersonsDataPerson${i}DocumentDocumentType`],
          docSeries: body[`merchantPersonsDataPerson${i}DocumentDocSeries`],
          docNumber: body[`merchantPersonsDataPerson${i}DocumentDocNumber`],
          docDate: moment(
            body[`merchantPersonsDataPerson${i}DocumentDocDate`],
          ).format(DATE_FORMAT),
          docCity: body[`merchantPersonsDataPerson${i}DocumentDocCity`],
          docPlace: body[`merchantPersonsDataPerson${i}DocumentDocPlace`],
        },
        adress: {
          area: body[`merchantPersonsDataPerson${i}AddressArea`],
          city: body[`merchantPersonsDataPerson${i}AddressCity`],
          zip: body[`merchantPersonsDataPerson${i}AddressZip`],
          street: body[`merchantPersonsDataPerson${i}AddressStreet`],
          house: body[`merchantPersonsDataPerson${i}AddressHouse`],
        },
      });

      if (data[i - 1].document.documentType !== '21') {
        delete data[i - 1].document.docSeries;
        delete data[i - 1].document.docCity;
        delete data[i - 1].document.docPlace;
      }
    }

    return data;
  };

  requestBody.params = {
    merchants: [
      {
        fullName: merchantFullName,
        addressBoss: {
          city: merchantAddressBossCity,
          guid: merchantAddressBossGuid,
        },
        personsData: getPersonsData(),
        services: [
          {
            rko: merchantServiceRko,
            tradePoints: [
              {
                name: merchantServiceTradePointName,
                mcc: merchantServiceTradePointMcc,
                okved: merchantServiceTradePointOkved,
                person: merchantServiceTradePointPerson,
                personPhone: merchantServiceTradePointPersonPhone,
                email: merchantServiceTradePointEmail,
                adress: {
                  area: merchantServiceTradePointAddressArea,
                  city: merchantServiceTradePointAddressCity,
                  zip: merchantServiceTradePointAddressZip,
                  street: merchantServiceTradePointAddressStreet,
                  house: merchantServiceTradePointAddressHouse,
                },
              },
            ],
          },
        ],
      },
    ],
  };

  if (merchantServiceTradePointIsOnlineStore) {
    requestBody.params.merchants[0].services[0].tradePoints[0].mainUrl = merchantServiceTradePointMainUrl;
  }

  return requestBody;
};

export default adaptSkbRequestBody;
