export const ActionType = {
  SET_CHART_PROCEEDS: 'analitics/loadChartProceeds',
  SET_CHART_PER_HOUR: 'analitics/loadChartPerHour',
  SET_CHART_AVERAGE_CHECK: 'analitics/loadChartAverageCheck',
  SET_SUMMARY: 'analitics/loadSummary',
  SET_BUYERS: 'analitics/loadBuyers',
  SET_POINTS: 'analitics/loadPoints',
  SET_TERMINALS: 'analitics/loadTerminals',
  SET_CUSTOMERS_TOP: 'SET_CUSTOMERS_TOP',
};

export const ActionCreator = {
  setChartProceeds: (data) => ({
    type: ActionType.SET_CHART_PROCEEDS,
    payload: data,
  }),
  setChartPerHour: (data) => ({
    type: ActionType.SET_CHART_PER_HOUR,
    payload: data,
  }),
  setChartAverageCheck: (data) => ({
    type: ActionType.SET_CHART_AVERAGE_CHECK,
    payload: data,
  }),
  setSummary: (data) => ({
    type: ActionType.SET_SUMMARY,
    payload: data,
  }),
  setBuyers: (data) => ({
    type: ActionType.SET_BUYERS,
    payload: data,
  }),
  setPoints: (data) => ({
    type: ActionType.SET_POINTS,
    payload: data,
  }),
  setTerminals: (data) => ({
    type: ActionType.SET_TERMINALS,
    payload: data,
  }),
  setCustomersTop: (data) => ({
    type: ActionType.SET_CUSTOMERS_TOP,
    payload: data,
  }),
};
