import { DatePicker, Form } from 'antd';
import PropTypes from 'prop-types';

import { DATE_FORMAT } from '../../../utils/constants/shared';

import styles from './range-picker-item.module.scss';

const { RangePicker } = DatePicker;

const RangePickerItem = ({
  formik,
  field,
  fields,
  currentClass,
  allowClear,
}) => {
  const handleRangePickerValueChange = (value) => {
    formik.setFieldValue(field, value);
  };

  return (
    <Form.Item
      label={fields[field] ? fields[field] : null}
      className={`${styles['filter__field-wrapper']} ${currentClass}`}
    >
      <RangePicker
        name={field}
        format={DATE_FORMAT}
        onChange={handleRangePickerValueChange}
        onOk={handleRangePickerValueChange}
        allowClear={allowClear}
        // allowEmpty
        value={formik.values[field]}
        className={styles.filter__rangepicker}
      />
    </Form.Item>
  );
};

RangePickerItem.propTypes = {
  formik: PropTypes.shape().isRequired,
  field: PropTypes.string.isRequired,
  fields: PropTypes.shape().isRequired,
  currentClass: PropTypes.shape(),
  allowClear: PropTypes.bool,
};

RangePickerItem.defaultProps = {
  currentClass: '',
  allowClear: false,
};

export default RangePickerItem;
