import { ActionType } from '../../action/user/user';

const initialState = {
  companies: [],
  company: {},
  user: {},
  pathBeforeAuth: null,
  isInitialDataLoading: true,
};

const setCompanies = (state, data) => ({
  ...state,
  companies: data,
});

const pushCompany = (state, data) => ({
  ...state,
  companies: [...state.companies, data],
});

const setCompany = (state, data) => ({
  ...state,
  company: data,
});

const setUser = (state, data) => ({
  ...state,
  user: data,
});

const setPathBeforeAuth = (state, data) => ({
  ...state,
  pathBeforeAuth: data,
});

const setIsInitialDataLoading = (state, data) => ({
  ...state,
  isInitialDataLoading: data,
});

const user = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_COMPANIES:
      return setCompanies(state, action.payload);

    case ActionType.PUSH_COMPANY:
      return pushCompany(state, action.payload);

    case ActionType.SET_COMPANY:
      return setCompany(state, action.payload);

    case ActionType.SET_USER:
      return setUser(state, action.payload);

    case ActionType.SET_PATH_BEFORE_AUTH:
      return setPathBeforeAuth(state, action.payload);

    case ActionType.SET_IS_INITIAL_DATA_LOADING:
      return setIsInitialDataLoading(state, action.payload);

    default:
      return state;
  }
};

export default user;
