import { Button, Input } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import styles from './control-panel.module.scss';

const { Search } = Input;

const ControlPanel = ({
  params,
  setParams,
  setInfo,
  buttonTitle,
  iconTitle,
  baseItem,
  setCurrentTablePage,
  loading,
}) => {
  const handleSearchFieldChange = (value) => {
    setParams({
      ...params,
      search: value,
      offset: 0,
    });
    setCurrentTablePage(1);
  };

  return (
    <div className={styles.control}>
      <Button
        type="primary"
        title={buttonTitle}
        onClick={() => setInfo({ ...baseItem })}
        className={styles['control__add-button']}
        disabled={loading}
      >
        <PlusOutlined />
        Добавить
      </Button>
      <InfoCircleOutlined title={iconTitle} className={styles['control__info-icon']} />
      <Search
        placeholder="Введите строку поиска"
        allowClear
        onSearch={handleSearchFieldChange}
        className={styles['control__search-field']}
        disabled={loading}
      />
    </div>
  );
};

ControlPanel.propTypes = {
  params: PropTypes.shape().isRequired,
  setParams: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  iconTitle: PropTypes.string.isRequired,
  baseItem: PropTypes.shape().isRequired,
  setCurrentTablePage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ControlPanel;
