import { ActionType } from '../../action/settings/profile';

const initialState = {
  profile: {},
  okveds: [],
};

const setProfile = (state, data) => ({
  ...state,
  profile: data,
});

const updateProfile = (state, data) => ({
  ...state,
  profile: data,
});

const setOkveds = (state, data) => ({
  ...state,
  okveds: data,
});

const profile = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_PROFILE:
      return setProfile(state, action.payload);

    case ActionType.UPDATE_PROFILE:
      return updateProfile(state, action.payload);

    case ActionType.SET_OKVEDS:
      return setOkveds(state, action.payload);

    default:
      return state;
  }
};

export default profile;
