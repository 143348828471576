import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import NProgress from 'nprogress';
import { ConfigProvider } from 'antd';
import { config } from '@fortawesome/fontawesome-svg-core';
import ruRu from 'antd/lib/locale/ru_RU';
import 'moment/locale/ru';

import { useStore } from '../store/store';
import MainLayout from '../layout/main-layout/main-layout';
import { PAGES } from '../utils/constants/shared';

import 'antd/dist/antd.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '../styles/styles.scss';
// import 'react-bootstrap-typeahead/css/Typeahead.css';

config.autoAddCss = false;

const MyApp = ({ Component, pageProps }) => {
  const store = useStore(pageProps.initialReduxState);

  const router = useRouter();
  const pageTitle = router.pathname.split('/')[1];

  useEffect(() => {
    const handleStart = () => {
      NProgress.start();
    };
    const handleStop = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  return (
    <Provider store={store}>
      <ConfigProvider locale={ruRu}>
        <MainLayout
          title={
            pageTitle && PAGES[pageTitle]
              ? PAGES[pageTitle].title
              : 'Личный кабинет'
          }
        >
          <Component {...pageProps} />
        </MainLayout>
      </ConfigProvider>
    </Provider>
  );
};

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MyApp;
