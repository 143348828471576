const getChartData = (data, fields) => {
  const values = [];

  data[fields.argument]
    && data[fields.argument].forEach((i, index) => {
      Object.keys(fields.titles).forEach((key) => {
        values.push({
          argument: i,
          value: data[key][index],
          category: fields.titles[key],
        });
      });
    });

  return values;
};

export default getChartData;
