import { ActionType } from '../../action/customer/customer';

const initialState = {
  info: {},
  buys: [],
};

const setCustomerInfo = (state, data) => ({
  ...state,
  info: data,
});

const setCustomerBuys = (state, data) => ({
  ...state,
  buys: data,
});

const customer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_CUSTOMER_INFO:
      return setCustomerInfo(state, action.payload);

    case ActionType.SET_CUSTOMER_BUYS:
      return setCustomerBuys(state, action.payload);

    default:
      return state;
  }
};

export default customer;
