const AntdColors = {
  PRIMARY: '#62DAAB',
  SECONDARY: '#7666F9',
  TERTIARY: '#6395F9',
  QUATERNARY: '#D62A0D',
  QUINARY: '#FAA219',
  SENARY: '#5B8FF9',
  SEPTENARY: '#65789B',
  OCTONARY: '#FF4D4F',
  NONARY: '#E8EDF3',
};

export default AntdColors;
