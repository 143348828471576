export const ActionType = {
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_UNIT_TYPES: 'SET_UNIT_TYPES',
  SET_GOODS: 'SET_GOODS',
};

export const ActionCreator = {
  setCategories: (data) => ({
    type: ActionType.SET_CATEGORIES,
    payload: data,
  }),

  setUnitTypes: (data) => ({
    type: ActionType.SET_UNIT_TYPES,
    payload: data,
  }),

  setGoods: (data) => ({
    type: ActionType.SET_GOODS,
    payload: data,
  }),
};
