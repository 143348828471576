import { ActionType } from '../../action/goods/goods';

const initialState = {
  categories: [],
  goods: [],
};

const setCategories = (state, data) => ({
  ...state,
  categories: data,
});

const setUnitTypes = (state, data) => ({
  ...state,
  unitTypes: data,
});

const setGoods = (state, data) => ({
  ...state,
  goods: data,
});

const goods = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_CATEGORIES:
      return setCategories(state, action.payload);

    case ActionType.SET_UNIT_TYPES:
      return setUnitTypes(state, action.payload);

    case ActionType.SET_GOODS:
      return setGoods(state, action.payload);

    default:
      return state;
  }
};

export default goods;
