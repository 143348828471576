export const ActionType = {
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_AVAILABLE_TREE: 'SET_AVAILABLE_TREE',
  SET_AVAILABLE_TELEGRAMS: 'SET_AVAILABLE_TELEGRAMS',
  SET_NEW_TELEGRAM: 'SET_NEW_TELEGRAM',
};

export const ActionCreator = {
  setNotifications: (data) => ({
    type: ActionType.SET_NOTIFICATIONS,
    payload: data,
  }),

  setAvailableTree: (data) => ({
    type: ActionType.SET_AVAILABLE_TREE,
    payload: data,
  }),

  setAvailableTelegrams: (data) => ({
    type: ActionType.SET_AVAILABLE_TELEGRAMS,
    payload: data,
  }),

  setNewTelegram: (data) => ({
    type: ActionType.SET_NEW_TELEGRAM,
    payload: data,
  }),
};
