import { useEffect, useMemo } from 'react';
import { Button, Form } from 'antd';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import styles from './filters.module.scss';

const Filters = ({
  params,
  setParams,
  filters,
  setCurrentTablePage,
  basisValues,
  validationSchema,
  loading,
  children,
  extraCancelAction,
}) => {
  const [form] = Form.useForm();

  const getInitialValues = () => {
    const values = {};

    Object.keys(basisValues).forEach((key) => {
      values[key] = filters[key] || basisValues[key];
    });

    return values;
  };

  const initialValues = useMemo(() => getInitialValues(), []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setParams({
        ...params,
        offset: 0,
        filters: values,
      });
      setCurrentTablePage(1);
    },
    onReset: () => {
      setParams({
        ...params,
        offset: 0,
        filters: basisValues,
      });
      setCurrentTablePage(1);
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    form.setFields(
      Object.keys(basisValues).map((key) => ({
        name: key,
        value: basisValues[key],
      })),
    );

    if (extraCancelAction) {
      extraCancelAction(formik, form);
    }
  };

  useEffect(() => {
    formik.setValues(filters);
    form.setFields(
      Object.keys(filters).map((key) => ({
        name: key,
        value: filters[key],
      })),
    );
  }, [filters]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...formik.values,
      }}
      onFinish={formik.handleSubmit}
      disabled={loading}
    >
      {children(formik, form)}
      <div className={styles['form-controls__wrapper']}>
        <Button type="primary" htmlType="submit">
          Применить фильтры
        </Button>
        <Button type="default" onClick={handleCancel} danger>
          Сбросить фильтры
        </Button>
      </div>
    </Form>
  );
};

Filters.propTypes = {
  params: PropTypes.shape().isRequired,
  setParams: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
  setCurrentTablePage: PropTypes.func.isRequired,
  basisValues: PropTypes.shape().isRequired,
  validationSchema: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.elementType.isRequired,
  extraCancelAction: PropTypes.func,
};

Filters.defaultProps = {
  extraCancelAction: null,
};

export default Filters;
