import { transformNumbers } from '../helpers/shared';

const yAxisLabelFormatter = (v) => ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => ''.concat(s, ','));

const lineTooltipFormatter = (r) => ({
  name: r.category,
  value: transformNumbers(r.value, 2),
});

const columnTooltipFormatter = (r) => ({
  name: r.category,
  value: transformNumbers(r.value, 2),
  title: `${r.argument} ч`,
});

export { yAxisLabelFormatter, lineTooltipFormatter, columnTooltipFormatter };
