import Cookies from 'js-cookie';
import { ActionType } from '../../action/authorization/authorization';

const initialState = {
  token: null,
  refresh: null,
  isLoading: true,
  isAuthorized: false,
  sessionToken: null,
};

const auth = (state, { access, refresh }) => {
  Cookies.set('token', access);
  Cookies.set('refresh', refresh);

  return {
    ...state,
    token: access,
    refresh,
    isAuthorized: true,
  };
};

const checkAuth = (state) => {
  const token = Cookies.get('token');
  const refresh = Cookies.get('refresh');
  const isAuthorized = token !== undefined;

  return {
    ...state,
    token,
    refresh,
    isAuthorized,
    isLoading: false,
  };
};

const setSessionToken = (state, { sessionToken }) => ({
  ...state,
  sessionToken,
  isLoading: false,
});

const logout = () => {
  Cookies.remove('token');
  Cookies.remove('refresh');
  Cookies.remove('payoutTerminal');

  if (window.jivo_api) {
    window.jivo_api.setCustomData([
      {
        title: 'Авторизован',
        content: 'Нет',
      },
      {
        title: 'ИНН',
        content: 'Неизвестно',
      },
    ]);
  }

  return {
    token: null,
    refresh: null,
    isAuthorized: false,
    sessionToken: null,
  };
};

const authorization = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOGIN:
      return auth(state, action.payload);

    case ActionType.LOGOUT:
      return logout(state);

    case ActionType.CHECK_AUTH:
      return checkAuth(state);

    case ActionType.SET_SESSION_TOKEN:
      return setSessionToken(state, action.payload);

    default:
      return state;
  }
};

export default authorization;
