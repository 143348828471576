import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Input, Select } from 'antd';
import PropTypes from 'prop-types';

import { FormField, ModalFormWrapper } from '../../../shared';
import { addMailing } from '../../../../services/customers';

// import styles from './mailing-modal.module.scss';

const { Option } = Select;

const MailingModal = ({
  addMailingModalVisible,
  setAddMailingModalVisible,
}) => {
  const segments = useSelector((state) => state.customers.segments);

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      segments: [],
      description: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    segments: Yup.array().min(1, 'Выберите сегменты'),
    description: Yup.string().required('Введите текст рассылки'),
  });

  const onSubmit = (values) => dispatch(
    addMailing({
      ...values,
    }),
  )
    .then(() => {
      setAddMailingModalVisible(!addMailingModalVisible);
    });

  const onCancel = () => {
    setAddMailingModalVisible(!addMailingModalVisible);
  };

  const handleSegmentsChange = (formik, currentSegments) => {
    formik.setFieldValue('segments', currentSegments);
  };

  return (
    <ModalFormWrapper
      title="Добавление рассылки"
      visibility={addMailingModalVisible}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage="Рассылка успешно добавлена"
      errorMessage="Не удалось добавить рассылку"
    >
      {(formik) => (
        <>
          <FormField
            formik={formik}
            mode="multiple"
            allowClear
            label="Сегменты для рассылки *"
            name="segments"
            placeholder="Выберите сегменты для рассылки"
            component={Select}
            onChange={(value) => handleSegmentsChange(formik, value)}
          >
            {segments.map((i) => (
              <Option value={i.id} key={i.id}>
                {i.name}
              </Option>
            ))}
          </FormField>

          <FormField
            formik={formik}
            label="Текст рассылки *"
            name="description"
            placeholder="Введите текст рассылки"
            component={Input.TextArea}
          />
        </>
      )}
    </ModalFormWrapper>
  );
};

MailingModal.propTypes = {
  addMailingModalVisible: PropTypes.bool.isRequired,
  setAddMailingModalVisible: PropTypes.func.isRequired,
};

export default MailingModal;
