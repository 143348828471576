import { Spin } from 'antd';
import styles from './spiner.module.scss';

const Spiner = () => (
  <div className={styles.spiner}>
    <Spin />
  </div>
);

export default Spiner;
