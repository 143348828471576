import { ActionType } from '../../action/reports/payments';

const initialState = {
  payments: {},
};

const setPayments = (state, data) => ({
  ...state,
  payments: data,
});

const payments = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_PAYMENTS:
      return setPayments(state, action.payload);

    default:
      return state;
  }
};

export default payments;
