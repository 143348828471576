import humps from 'humps';
import { message } from 'antd';
import { ActionCreator } from '../store/action/user/user';
import { adaptRequestBodyPhoneField } from '../utils/helpers/shared';

export const loadCompanies = () => (dispatch, _getState, api) => api.post('/users.getCompanies')
  .then(({ data }) => {
    // Если в localStorage не содержит id и data не пустая, то записать из массива в localStorage первую компанию.
    if (!localStorage.getItem('id') && data.length > 0) {
      localStorage.setItem('id', data[0].id);
      // localStorage.setItem('id', '39b6550beb1c4217857fea4ee9c587ee');
    }

    /**
     * Условие необходимо, чтобы отобразить корреткно компанию,
     * если пользователь заходит под разными учетными записями с одного браузера.
     * ---
     * Если localStorage содержит id и data не пустая, то проверяем текущий id по списку компаний из data.
     * Если есть совпадения по id, значит пользователь зашел под учетной записью, которую использовал в последний раз,
     * в этом случае ничего не делаем, id компании верное.
     * Если совпадений нет, то пользователь зашел под новой учетной записью и нужно перезаписать id компании.
    */
    if (localStorage.getItem('id') && data.length > 0) {
      const compayId = localStorage.getItem('id');

      if (!data.some((company) => company.id === compayId)) {
        localStorage.setItem('id', data[0].id);
        // localStorage.setItem('id', '39b6550beb1c4217857fea4ee9c587ee');
      }
    }

    dispatch(ActionCreator.setCompanies(humps.camelizeKeys(data)));
  })
  .catch(() => {
    message.error('Не удалось загрузить компании');
  });

export const loadCompany = () => (dispatch, _getState, api) => api.post('/users.getCompany')
  .then(({ data }) => {
    dispatch(ActionCreator.setCompany(humps.camelizeKeys(data)));

    return humps.camelizeKeys(data);
  })
  .catch(() => {
    message.error('Не удалось загрузить компанию');
  });

export const addCompany = (body, isFirstCompany) => (dispatch, _getState, api) => api.post('/registration/company', adaptRequestBodyPhoneField(body))
  .then(({ data }) => {
    message.success('Успешно добавлено');

    if (isFirstCompany) {
      localStorage.setItem('id', data.id);
    }

    dispatch(loadCompanies());
  })
  .catch((error) => {
    message.error((error.response && error.response.message) || 'Не удалось добавить');
  });

export const loadUser = () => (dispatch, _getState, api) => (
  api.post('/users.getUser')
    .then(({ data }) => {
      dispatch(ActionCreator.setUser(humps.camelizeKeys(data)));

      return humps.camelizeKeys(data);
    })
);

export const loadTelegramInfo = () => (dispatch, _getState, api) => api.post('/users.addTelegram');
