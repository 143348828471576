import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import {
  Button, message, Modal, Upload,
} from 'antd';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './upload-image.module.scss';

const UploadImage = (props) => {
  const {
    formik, filePath, urlPath, previewTitle, uploadService,
  } = props;

  const [loading, setLoading] = useState('');
  const [basisImageUrl, setBasisImageUrl] = useState(formik.values[urlPath]);
  const [imageUrl, setImageUrl] = useState(formik.values[urlPath]);
  const [previewVisible, setPreviewVisible] = useState(false);

  const basisFilePath = formik.values[filePath];

  const dispatch = useDispatch();

  const fileInput = useRef(null);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <p className={styles['upload-button']}>Добавьте логотип</p>
    </div>
  );

  const getBinary = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsArrayBuffer(img);
  };

  const beforeUpload = (file) => {
    const isImage = file.type.slice(0, 5).toLowerCase() === 'image';
    if (!isImage) {
      message.error('Вы можете загрузить только изображение');
    }
    file.status = 'error';
    return isImage;
  };

  const handleUploader = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
    }

    if (info.file.status === 'error') {
      message.error(`${info.file.name} не загружен.`);
      setLoading(false);
    }

    if (info.file.status === 'done') {
      getBinary(info.file.originFileObj, (url) => {
        setImageUrl();

        // Запрос на загрузку изображения
        dispatch(uploadService(url, info.file.type, formik.values.id))
          .then(({ data }) => {
            message.success(`${info.file.name} загружен.`);
            setImageUrl(data.url);
            formik.setFieldValue(filePath, data.filename);
            setLoading(false);
          })
          .catch(() => {
            info.file.status = 'error';
            message.error(`${info.file.name} не загружен.`);
            setLoading(false);
          });
      });
    }
  };

  const handleEditButtonClick = (evt) => {
    evt.stopPropagation();
    fileInput.current.upload.uploader.onClick();
  };

  const handlePreviewButtonClick = (evt) => {
    evt.stopPropagation();
    setPreviewVisible(true);
  };

  const handlePreviewCancel = (evt) => {
    evt.stopPropagation();
    setPreviewVisible(false);
  };

  const handleUndoButtonClick = (evt) => {
    evt.stopPropagation();
    setImageUrl(basisImageUrl);
    formik.setFieldValue(filePath, basisFilePath);
  };

  const handleDeleteButtonClick = (evt) => {
    evt.stopPropagation();
    setBasisImageUrl('');
    setImageUrl('');
    formik.setFieldValue(filePath, '');
  };

  const handleWrongClick = (evt) => {
    evt.stopPropagation();
  };

  const maskButtons = [
    {
      icon: EditOutlined,
      title: 'Сменить логотип',
      handler: handleEditButtonClick,
    },
    {
      icon: EyeOutlined,
      title: 'Предпросмотр',
      handler: handlePreviewButtonClick,
    },
    {
      icon: UndoOutlined,
      title: 'Отменить изменения',
      handler: handleUndoButtonClick,
    },
    {
      icon: DeleteOutlined,
      title: 'Удалить логотип',
      handler: handleDeleteButtonClick,
    },
  ];

  return (
    <>
      <Upload
        accept="image/*"
        onChange={handleUploader}
        beforeUpload={beforeUpload}
        showUploadList={false}
        listType="picture-card"
        name="avatar"
        className={styles['avatar-uploader']}
        ref={fileInput}
      >
        {imageUrl ? (
          <>
            <img
              src={imageUrl}
              alt="avatar"
              className={styles['upload-image']}
            />
            <div role="none" className={styles.mask} onClick={handleWrongClick}>
              {maskButtons.map((item) => {
                const { icon: Icon, title, handler } = item;

                return (
                  <Button
                    key={title}
                    type="text"
                    className={styles.mask__button}
                    icon={<Icon className={styles['mask__button-icon']} />}
                    title={title}
                    onClick={handler}
                  />
                );
              })}
            </div>
          </>
        ) : (
          uploadButton
        )}
      </Upload>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handlePreviewCancel}
        destroyOnClose
      >
        <img
          className={styles['preview-image']}
          src={imageUrl}
          alt={previewTitle}
        />
      </Modal>
    </>
  );
};

UploadImage.propTypes = {
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
  filePath: PropTypes.string.isRequired,
  urlPath: PropTypes.string.isRequired,
  previewTitle: PropTypes.string.isRequired,
  uploadService: PropTypes.func.isRequired,
};

export default UploadImage;
