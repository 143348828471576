const modal2FAApplications = [
  {
    title: 'Облачные решения (рекомендуется, поскольку вы можете восстановить доступ в случае потери аппаратного устройства):',
    applications: [
      {
        url: 'https://authy.com/',
        title: 'Authy',
      },
      {
        url: 'https://duo.com/',
        title: 'Duo',
      },
    ],
  },
  {
    title: 'Проприетарное программное обеспечение:',
    applications: [
      {
        url: 'https://support.google.com/accounts/answer/1066447?hl=en',
        title: 'Google Authenticator',
      },
      {
        url: 'https://www.microsoft.com/en-us/security/mobile-authenticator-app',
        title: 'Microsoft Authenticator',
      },
    ],
  },
  {
    title: 'Бесплатное программное обеспечение:',
    applications: [
      {
        url: 'https://getaegis.app/',
        title: 'Aegis Authenticator',
      },
      {
        url: 'https://freeotp.github.io/',
        title: 'FreeOTP',
      },
    ],
  },
];

export { modal2FAApplications };
