import { ActionType } from '../../action/points-of-sale/points-of-sale';

const initialState = {
  pointsOfSale: [],
};

const setPointsOfSale = (state, data) => ({
  ...state,
  pointsOfSale: data,
});

const pointsOfSale = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_POINTS_OF_SALE:
      return setPointsOfSale(state, action.payload);

    default:
      return state;
  }
};

export default pointsOfSale;
