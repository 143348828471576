import { BankSuggestions } from 'react-dadata';

import { DADATA_TOKEN } from '../../shared';

const taxSystems = {
  osnvat110: 'ОСН (в т.ч НДС 10%)',
  osnvat120: 'ОСН (в т.ч НДС 20%)',
  usn_incomenone: 'УСН, 6%',
  usn_income_outcomenone: 'УСН, 15%',
  envdnone: 'ЕНВД',
  patentnone: 'Патент',
  osnvat10: 'ОСН (плюс 10% НДС)',
  osnvat20: 'ОСН (плюс 20% НДС)',
  esnnone: 'ЕСН',
};

const changePasswordFields = [
  {
    name: 'oldPassword',
    placeholder: 'Старый пароль',
  },
  {
    name: 'password',
    placeholder: 'Новый пароль',
  },
  {
    name: 'confirmPassword',
    placeholder: 'Повтор пароля',
  },
];

const accountAddModalFields = [
  {
    label: 'Расчетный счет',
    name: 'number',
    placeholder: 'Введите расчетный счет',
  },
  {
    label: 'Банк',
    name: 'bankName',
    token: DADATA_TOKEN,
    component: BankSuggestions,
    inputProps: {
      className: 'ant-input',
      placeholder: 'Введите название банка или БИК',
      name: 'bankName',
    },
    count: 5,
    pathParts: ['value'],
    dependentFields: [
      {
        name: 'bankBic',
        pathParts: ['data', 'bic'],
      },
      {
        name: 'corAccount',
        pathParts: ['data', 'correspondent_account'],
      },
    ],
  },
  {
    name: 'bankBic',
    hidden: true,
  },
  {
    name: 'corAccount',
    hidden: true,
    disabled: true,
  },
];

const sbpSuccessStatuses = {
  1: 'Заявка на подключение принята',
  2: 'СБП подключена',
};

const coefficients = [
  7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1,
];

const genders = [
  {
    value: 'male',
    title: 'Мужской',
  },
  {
    value: 'female',
    title: 'Женский',
  },
];

const personTypes = [
  {
    value: 'BENEFIZIAR',
    title: 'Бенефициар',
  },
  {
    value: 'FOUNDER',
    title: 'Учредитель',
  },
  {
    value: 'BOSS',
    title: 'Директор',
  },
  {
    value: 'Self-employment',
    title: 'Самозанятый',
  },
];

const documentTypes = [
  {
    value: '21',
    title: 'Паспорт гражданина РФ',
  },
  {
    value: '10',
    title: 'Паспорт иностранного гражданина',
  },
];

const persons = ['Person1', 'Person2', 'Person3', 'Person4', 'Person5'];

export {
  taxSystems,
  changePasswordFields,
  accountAddModalFields,
  sbpSuccessStatuses,
  coefficients,
  genders,
  personTypes,
  documentTypes,
  persons,
};
