export const ActionType = {
  SET_PAYMENTS: 'SET_PAYMENTS',
};

export const ActionCreator = {
  setPayments: (data) => ({
    type: ActionType.SET_PAYMENTS,
    payload: data,
  }),
};
