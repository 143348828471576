const paymentsStatus = {
  created: 0,
  partiallyPaid: 1,
  paid: 2,
  hold: 3,
  canceled: 4,
  error: 6,
};

const profitChartFields = {
  argument: 'date',
  titles: {
    new: 'Новые покупатели',
    const: 'Постоянные покупатели',
    common: 'Общий оборот',
  },
};

const RefundStatuses = {
  NO_REFUNDS: 0,
  PARTIALLY_REFUND: 1,
  FULL_REFUND: 2,
};

const periodRangePickersNames = {
  period: '',
};

export {
  paymentsStatus,
  profitChartFields,
  RefundStatuses,
  periodRangePickersNames,
};
