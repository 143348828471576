import humps from 'humps';

import { ActionCreator } from '../../store/action/settings/general';
import { ActionCreator as AuthActionCreator } from '../../store/action/authorization/authorization';

import { adaptRequestBody } from '../../utils/helpers/settings/general';

export const loadCommon = () => (dispatch, _getState, api) => (
  api.post('/settings/common')
    .then(({ data }) => {
      dispatch(ActionCreator.setCommon(humps.camelizeKeys(data)));
    })
);

export const loadAccounts = () => (dispatch, _getState, api) => (
  api.post('/settings/checking_account/list')
    .then(({ data }) => {
      dispatch(ActionCreator.setAccounts(humps.camelizeKeys(data)));
    })
);

export const setActivateAccount = (accountNumber) => (dispatch, _getState, api) => (
  api.post('/settings/checking_account/activate', { number: accountNumber })
    .then(() => {
      const changeActiveAccounts = () => _getState().general.accounts.map((item) => {
        item.number !== accountNumber ? item.isActive = false : item.isActive = true;
        return item;
      });
      dispatch(ActionCreator.setActivateAccount(changeActiveAccounts()));
    })
);

export const addAccount = (account) => (dispatch, _getState, api) => (
  api.post('/settings/checking_account/add', humps.decamelizeKeys(account))
    .then(() => {
      dispatch(ActionCreator.addAccount(account));
      return account;
    })
);

export const changeName = (body) => (dispatch, _getState, api) => (
  api.post('/users/change_name', humps.decamelizeKeys(body))
);

export const changePassword = (body) => (dispatch, _getState, api) => (
  api.post('/users/change_password', humps.decamelizeKeys(body))
);

export const activate2FA = (body) => (dispatch, _getState, api) => (
  api.post('/users/two_factor/verify', humps.decamelizeKeys(body))
    .then(({ data }) => {
      dispatch(AuthActionCreator.login(humps.camelizeKeys(data)));
    })
);

export const deactivate2FA = (body) => (dispatch, _getState, api) => (
  api.post('/users/two_factor/delete', humps.decamelizeKeys(body))
    .then(({ data }) => {
      dispatch(AuthActionCreator.login(humps.camelizeKeys(data)));
    })
);

export const loadPreActivate2FAInfo = () => (dispatch, _getState, api) => (
  api.get('/users/two_factor/add')
    .then(({ data }) => {
      dispatch(ActionCreator.setPreActivate2FAInfo(humps.camelizeKeys(data)));
    })
);

export const create2FACodes = (body) => (dispatch, _getState, api) => (
  api.post('/users/two_factor/codes/create', humps.decamelizeKeys(body))
    .then(({ data }) => {
      dispatch(ActionCreator.set2FACodes(humps.camelizeKeys(data)));
    })
);

export const load2FACodesList = (body) => (dispatch, _getState, api) => api
  .post('/users/two_factor/codes/list', humps.decamelizeKeys(body))
  .then(({ data }) => {
    dispatch(ActionCreator.set2FACodes(humps.camelizeKeys(data)));
  });

export const deactivate2FAByRestoreCode = (body) => (dispatch, _getState, api) => (
  api.post('/users/two_factor/codes/delete', humps.decamelizeKeys(body))
    .then(({ data }) => {
      dispatch(AuthActionCreator.login(humps.camelizeKeys(data)));
    })
);

export const checkSbpStatus = (body) => (dispatch, _getState, api) => (
  api.post('/settings/sbp/check', humps.decamelizeKeys(body))
    .then(({ data }) => {
      dispatch(ActionCreator.setSbpStatus(humps.camelizeKeys(data)));
    })
);

export const connectSbp = (body, adapterData) => (dispatch, _getState, api) => (
  api.post('/settings/sbp/connect', humps.decamelizeKeys(adaptRequestBody(body, adapterData)))
    .then(({ data }) => {
      dispatch(ActionCreator.setSbpConnectionStatus(humps.camelizeKeys(data)));
      return data;
    })
);

export const loadOkveds = () => (dispatch, _getState, api) => (
  api.post('/settings/okveds')
    .then(({ data }) => {
      dispatch(ActionCreator.setOkveds(humps.camelizeKeys(data)));
    })
);

export const check2FAStatus = () => (dispatch, _getState, api) => (
  api.get('/settings/two_factor_auth')
    .then(({ data }) => {
      dispatch(ActionCreator.set2FAStatus(humps.camelizeKeys(data)));
    })
    .catch(() => {})
);

export const change2FAStatus = (body) => (dispatch, _getState, api) => (
  api.post('/settings/two_factor_auth', humps.decamelizeKeys(body))
);
