const periods = {
  week: {
    title: 'За неделю',
    unit: 'days',
    value: 7,
  },
  month: {
    title: 'За месяц',
    unit: 'days',
    value: 29,
  },
  year: {
    title: 'За год',
    unit: 'month',
    value: 12,
  },
};

const DATE_FORMAT = 'DD.MM.YYYY';
const DATE_TIME_FORMAT = 'DD.MM.YYYY, HH:mm';
const DATE_TIME_REVERSE_FORMAT = 'YYYY.MM.DDTHH:mm';

export {
  periods,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_REVERSE_FORMAT,
};
