import { cloneDeep } from 'lodash';

import adaptDateTimeFormat from './_adapt-date-time-format';

const adaptPeriod = (period) => {
  const basisPeriod = period.map((item) => cloneDeep(item));

  return {
    period: [
      adaptDateTimeFormat(basisPeriod[0]),
      adaptDateTimeFormat(basisPeriod[1].add(1, 'd')),
    ],
  };
};

export default adaptPeriod;
