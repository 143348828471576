import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { FormField, ModalFormWrapper } from '../../../shared';

import { changePassword } from '../../../../services/settings/general';

import { changePasswordFields } from '../../../../utils/constants/settings/general';

// import styles from './modal-change-password.module.scss';

const ModalChangePassword = ({
  changePasswordModalVisible,
  setChangePasswordModalVisible,
}) => {
  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      oldPassword: '',
      password: '',
      confirmPassword: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required('Поле обязательно для заполнения'),
    password: Yup.string()
      .required('Поле обязательно для заполнения')
      .min(8, 'Пароль должен содержать минимум 8 символов.')
      .matches(/[a-zA-Z]/, 'Введённый пароль состоит только из цифр'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
      .required('Поле обязательно для заполнения'),
  });

  const onSubmit = (values) => {
    delete values.confirmPassword;
    return dispatch(changePassword(values))
      .then(() => {
        setChangePasswordModalVisible(!changePasswordModalVisible);
      });
  };

  const onCancel = () => {
    setChangePasswordModalVisible(!changePasswordModalVisible);
  };

  return (
    <ModalFormWrapper
      title="Смена пароля"
      visibility={changePasswordModalVisible}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage="Новый пароль сохранен"
      errorMessage="Произошла ошибка"
      submitButtonTitle="Сохранить"
    >
      {(formik) => changePasswordFields.map((item) => (
        <FormField
          formik={formik}
          name={item.name}
          placeholder={item.placeholder}
          component={Input.Password}
          prefix={<LockOutlined />}
          key={item.name}
        />
      ))}
    </ModalFormWrapper>
  );
};

ModalChangePassword.propTypes = {
  changePasswordModalVisible: PropTypes.bool.isRequired,
  setChangePasswordModalVisible: PropTypes.func.isRequired,
};

export default ModalChangePassword;
