const transformNumbers = (data, decimalsLength = 0) => {
  if (data === 0) {
    return data;
  }

  const price = Number.prototype.toFixed.call(parseFloat(data) || 0, decimalsLength);

  return price.replace(/(\.)/g, ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\u00A0');
};

export default transformNumbers;
