import {
  DiffOutlined,
  DollarCircleOutlined,
  ExportOutlined,
  HddOutlined,
  HomeOutlined,
  LineChartOutlined,
  SettingOutlined,
  ShareAltOutlined,
  TeamOutlined,
  WalletOutlined,
} from '@ant-design/icons';

const PAGES = {
  registrationCompany: {
    title: 'Регистрация компании',
    route: '/registration-company',
  },
  login: {
    title: 'Авторизация',
    route: '/',
  },
  // checkCode: {
  //   title: 'Проверка кода',
  //   route: '/check-code',
  // },
  // logout вспомогательная страница, позволяет сбросить isAuthorized в false.
  // Используется в api.js, т.к. на данный момент нет возможности получить dispatch внутри файла.
  // P.S. Если у кого-то возникнит более элегантное РАБОЧЕЕ решение, просьба дать знать.
  logout: {
    title: '',
    route: '/logout',
  },
  dashboard: {
    title: 'Главная',
    route: '/dashboard',
    icon: <HomeOutlined />,
    companyTypes: [0],
  },
  stat: {
    title: 'Аналитика',
    route: '/stat',
    icon: <LineChartOutlined />,
    companyTypes: [0],
  },
  bonus: {
    title: 'Бонусы',
    route: '/bonus',
    icon: <DollarCircleOutlined />,
    companyTypes: [0],
  },
  payouts: {
    title: 'Выплаты',
    route: '/payouts',
    icon: <WalletOutlined />,
    companyTypes: [2],
  },
  terminals: {
    title: 'Терминалы',
    route: '/terminals',
    icon: <HddOutlined />,
    companyTypes: [0, 2],
  },
  customers: {
    title: 'Покупатели',
    route: '/customers',
    icon: <TeamOutlined />,
    companyTypes: [0],
  },
  reports: {
    title: 'Отчеты',
    route: '/reports',
    icon: <DiffOutlined />,
    companyTypes: [0, 2],
  },
  customer: {
    title: 'Информация о покупателе',
    route: '/customer',
  },
  // goods: {
  //   title: 'Товары и услуги',
  //   route: '/goods',
  //   icon: <ShoppingCartOutlined />,
  //   companyTypes: [2],
  // },
  // messages: {
  //   title: 'Сообщения',
  //   route: '/messages',
  //   icon: <MailOutlined />,
  //   companyTypes: [2],
  // },
  newMessages: {
    title: 'Сообщения',
    route: '/messages/new',
  },
  settings: {
    title: 'Настройки',
    route: '/settings',
    icon: <SettingOutlined />,
    companyTypes: [0, 2],
  },
  connection: {
    title: 'Подключение',
    route: '/connection',
    icon: <ShareAltOutlined />,
    companyTypes: [0, 2],
  },
  exit: {
    title: 'Выйти',
    route: '/',
    icon: <ExportOutlined />,
  },
};

export default PAGES;
