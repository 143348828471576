export const ActionType = {
  SET_PROFILE: 'settings/setProfile',
  UPDATE_PROFILE: 'settings/updateProfile',
  SET_OKVEDS: 'settings/setOkveds',
};

export const ActionCreator = {
  setProfile: (data) => ({
    type: ActionType.SET_PROFILE,
    payload: data,
  }),
  updateProfile: (data) => ({
    type: ActionType.UPDATE_PROFILE,
    payload: data,
  }),
  setOkveds: (data) => ({
    type: ActionType.SET_OKVEDS,
    payload: data,
  }),
};
