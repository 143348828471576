import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Button, Input } from 'antd';
import { LockOutlined, SwapOutlined, UserOutlined } from '@ant-design/icons';
import ReactInputMask from 'react-input-mask';
import PropTypes from 'prop-types';

import { FormField, ModalFormWrapper } from '../../../shared';

import {
  deactivate2FA,
  deactivate2FAByRestoreCode,
} from '../../../../services/settings/general';
import { loadUser } from '../../../../services/user';

import { maskedNumberFieldFormatter } from '../../../../utils/formatters';

import styles from './modal-deactivate-2fa.module.scss';

const ModalDeactivate2FA = ({
  deactivate2FAModalVisible,
  setDeactivate2FAModalVisible,
}) => {
  const [isForgotCode, setIsForgotCode] = useState(false);

  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      password: '',
      code: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    password: Yup.string().required('Поле обязательно для заполнения'),
    code: Yup.string()
      .test({
        name: 'Length',
        test: (value) => {
          if (value) {
            return isForgotCode ? value.length === 8 : value.length === 6;
          }

          return true;
        },
        message: 'Введите корректный код',
      })
      .required('Поле обязательно для заполнения'),
  });

  const onSubmit = (values) => {
    const service = isForgotCode ? deactivate2FAByRestoreCode : deactivate2FA;

    return dispatch(service(values)).then(() => {
      dispatch(loadUser());
      setDeactivate2FAModalVisible(!deactivate2FAModalVisible);
    });
  };

  const onCancel = () => {
    setDeactivate2FAModalVisible(!deactivate2FAModalVisible);
  };

  const handleRestoreCodeButtonClick = (formik, form) => {
    formik.setFieldValue('code', '');
    form.setFieldsValue({ code: '' });
    setIsForgotCode(true);
  };

  const handleBackToCheckCodeButtonClick = (formik, form) => {
    formik.setFieldValue('code', '');
    form.setFieldsValue({ code: '' });
    setIsForgotCode(false);
  };

  return (
    <ModalFormWrapper
      title="Двухфакторная аутентификация (деактивация)"
      visibility={deactivate2FAModalVisible}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      successMessage="Двухфакторная аутентификация деактивирована"
      errorMessage="Произошла ошибка"
      submitButtonTitle="Деактивировать"
    >
      {(formik, form, isSending) => (
        <>
          <FormField
            formik={formik}
            label="Текущий пароль"
            name="password"
            placeholder="Введите текущий пароль"
            component={Input.Password}
            prefix={<LockOutlined />}
          />
          <FormField
            formik={formik}
            label={isForgotCode ? 'Код восстановления' : 'Проверочный код'}
            name="code"
            placeholder={
              isForgotCode
                ? 'Введите код восстановления'
                : 'Введите проверочный код'
            }
            component={ReactInputMask}
            mask={isForgotCode ? '99999999' : '999999'}
            maskChar="✱"
            onChange={(evt) => formik.setFieldValue(
              'code',
              maskedNumberFieldFormatter(evt.target.value),
            )}
            prefix={<UserOutlined />}
            suffix={(
              <Button
                type="default"
                title={
                  isForgotCode
                    ? 'Ввести проверочный код'
                    : 'Ввести код восстановления'
                }
                onClick={() => (isForgotCode
                  ? handleBackToCheckCodeButtonClick(formik, form)
                  : handleRestoreCodeButtonClick(formik, form))}
                className={styles['deactivate-2fa__restore-code-toggle-button']}
                disabled={isSending}
              >
                <SwapOutlined
                  className={
                    styles['deactivate-2fa__restore-code-toggle-button-icon']
                  }
                />
              </Button>
            )}
            hint={
              isForgotCode
                ? 'Введите код восстановления или вернитесь к введению проверочного кода из вашего приложения для двухфакторной аутентификации.'
                : 'Введите код из вашего приложения для двухфакторной аутентификации. Если вы потеряли свое устройство, вы можете ввести один из своих кодов восстановления.'
            }
          >
            {(inputProps) => <Input {...inputProps} />}
          </FormField>
        </>
      )}
    </ModalFormWrapper>
  );
};

ModalDeactivate2FA.propTypes = {
  deactivate2FAModalVisible: PropTypes.bool.isRequired,
  setDeactivate2FAModalVisible: PropTypes.func.isRequired,
};

export default ModalDeactivate2FA;
