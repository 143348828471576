export const ActionType = {
  SET_COMMON: 'settings/setCommon',
  SET_ACCOUNTS: 'settings/setAccounts',
  SET_ACTIVE_ACCOUNT: 'settings/setActivateAccount',
  ADD_ACCOUNT: 'settings/addAccount',
  SET_SBP_STATUS: 'SET_SBP_STATUS',
  SET_SBP_CONNECTION_STATUS: 'SET_SBP_CONNECTION_STATUS',
  SET_OKVEDS: 'SET_OKVEDS',
  SET_PRE_ACTIVATE_2FA_INFO: 'SET_PRE_ACTIVATE_2FA_INFO',
  SET_2FA_STATUS: 'SET_2FA_STATUS',
  SET_2FA_CODES: 'SET_2FA_CODES',
};

export const ActionCreator = {
  setCommon: (data) => ({
    type: ActionType.SET_COMMON,
    payload: data,
  }),

  setAccounts: (data) => ({
    type: ActionType.SET_ACCOUNTS,
    payload: data,
  }),

  setActivateAccount: (data) => ({
    type: ActionType.SET_ACTIVE_ACCOUNT,
    payload: data,
  }),

  addAccount: (data) => ({
    type: ActionType.ADD_ACCOUNT,
    payload: data,
  }),

  setSbpStatus: (data) => ({
    type: ActionType.SET_SBP_STATUS,
    payload: data,
  }),

  setSbpConnectionStatus: (data) => ({
    type: ActionType.SET_SBP_CONNECTION_STATUS,
    payload: data,
  }),

  setOkveds: (data) => ({
    type: ActionType.SET_OKVEDS,
    payload: data,
  }),

  setPreActivate2FAInfo: (data) => ({
    type: ActionType.SET_PRE_ACTIVATE_2FA_INFO,
    payload: data,
  }),

  set2FAStatus: (data) => ({
    type: ActionType.SET_2FA_STATUS,
    payload: data,
  }),

  set2FACodes: (data) => ({
    type: ActionType.SET_2FA_CODES,
    payload: data,
  }),
};
