import { ActionType } from '../../action/stat/stat';

const initialState = {
  chartProceeds: [],
  chartPerHour: [],
  chartAverageCheck: [],
  summary: {},
  buyers: {},
  points: {},
  terminals: {},
  customersTop: [],
};

const setChartProceeds = (state, data) => ({
  ...state,
  chartProceeds: data,
});

const setChartPerHour = (state, data) => ({
  ...state,
  chartPerHour: data,
});

const setChartAverageCheck = (state, data) => ({
  ...state,
  chartAverageCheck: data,
});

const setSummary = (state, data) => ({
  ...state,
  summary: data,
});

const setBuyers = (state, data) => ({
  ...state,
  buyers: data,
});

const setPoints = (state, data) => ({
  ...state,
  points: data,
});

const setTerminals = (state, data) => ({
  ...state,
  terminals: data,
});

const setCustomersTop = (state, data) => ({
  ...state,
  customersTop: data,
});

const stat = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_CHART_PROCEEDS:
      return setChartProceeds(state, action.payload);

    case ActionType.SET_CHART_PER_HOUR:
      return setChartPerHour(state, action.payload);

    case ActionType.SET_CHART_AVERAGE_CHECK:
      return setChartAverageCheck(state, action.payload);

    case ActionType.SET_SUMMARY:
      return setSummary(state, action.payload);

    case ActionType.SET_BUYERS:
      return setBuyers(state, action.payload);

    case ActionType.SET_POINTS:
      return setPoints(state, action.payload);

    case ActionType.SET_TERMINALS:
      return setTerminals(state, action.payload);

    case ActionType.SET_CUSTOMERS_TOP:
      return setCustomersTop(state, action.payload);

    default:
      return state;
  }
};

export default stat;
