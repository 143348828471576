import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Form } from 'antd';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FormFieldSelector, ModalWrapper } from '../../../shared';

import { addCompany } from '../../../../services/user';

import { phoneNumberFormatter } from '../../../../utils/formatters';

import { addCompanyModalFields } from '../../../../utils/constants/shared';

import 'react-dadata/dist/react-dadata.css';
import styles from './modal-add-company.module.scss';

const ModalAddCompany = ({
  isOpen, onSuccess, onCancel, isFirstCompany,
}) => {
  const [isSending, setIsSending] = useState(false);

  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const initialValues = {
    name: '',
    phone: '',
    inn: '',
    ogrn: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(
        32,
        'Название организации (имя по вывеске) должно содержать не более 32 символов',
      )
      .required('Поле обязательно для заполнения'),
    phone: Yup.string()
      .length(10, 'Введите корректный номер телефона')
      .required('Поле обязательно для заполнения'),
    inn: Yup.string().required('Необходимо выбрать организацию'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setIsSending(true);
      dispatch(addCompany(values, isFirstCompany))
        .then(() => {
          setIsSending(false);
          onSuccess();
        })
        .catch(() => {
          setIsSending(false);
        });
    },
  });

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
      form.resetFields();
    }
  }, [isOpen]);

  useEffect(() => {
    if (user.phone) {
      formik.setFieldValue('phone', phoneNumberFormatter(user.phone));
      form.setFieldsValue({ phone: phoneNumberFormatter(user.phone) });
    }
  }, [user]);

  return (
    <ModalWrapper
      title="Добавить организацию"
      open={isOpen}
      onCancel={onCancel}
      closeIcon={isFirstCompany ? <ArrowLeftOutlined /> : <CloseOutlined />}
      className={isFirstCompany ? 'sign-up' : ''}
    >
      {(setIsFormFieldsChanged) => (
        <Form
          form={form}
          layout="vertical"
          onFinish={formik.handleSubmit}
          onFieldsChange={() => setIsFormFieldsChanged(true)}
        >
          <FormFieldSelector
            formik={formik}
            form={form}
            data={addCompanyModalFields}
          />
          <Form.Item
            className={
              !isFirstCompany ? styles['modal-add-company__form-controls'] : ''
            }
          >
            <Button
              // disabled={!formik.isValid || isSending}
              disabled={isSending}
              type="primary"
              htmlType="submit"
              className={isFirstCompany ? 'sign-up__btn' : ''}
            >
              Добавить
            </Button>
          </Form.Item>
        </Form>
      )}
    </ModalWrapper>
  );
};

ModalAddCompany.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isFirstCompany: PropTypes.bool,
};

ModalAddCompany.defaultProps = {
  isFirstCompany: false,
};

export default ModalAddCompany;
