import humps from 'humps';

import { ActionCreator } from '../store/action/customers/customers';

export const loadSegments = () => (dispatch, _getState, api) => api.post('/customers/segments/list').then(({ data }) => {
  dispatch(ActionCreator.setSegments(humps.camelizeKeys(data)));
});

export const loadCustomers = (body) => (dispatch, _getState, api) => api.post('/customers/list', humps.decamelizeKeys(body)).then(({ data }) => {
  dispatch(ActionCreator.setCustomers(humps.camelizeKeys(data)));
});

export const createSegment = (body) => (dispatch, _getState, api) => api.post('/customers/segments/create', humps.decamelizeKeys(body));

export const deleteSegment = (body) => (dispatch, _getState, api) => api.post('/customers/segments/delete', humps.decamelizeKeys(body));

export const addMailing = (body) => (dispatch, _getState, api) => api.post('/customers/mailing/add', humps.decamelizeKeys(body));
