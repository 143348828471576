import { ActionType } from '../../action/connection/connection';

const initialState = {
  connectionList: {},
};

const setConnectionList = (state, data) => ({
  ...state,
  connectionList: data,
});

const connection = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_CONNECTION_LIST:
      return setConnectionList(state, action.payload);

    default:
      return state;
  }
};

export default connection;
