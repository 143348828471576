import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { ActionCreator } from '../store/action/authorization/authorization';
import { ActionCreator as UserActionCreator } from '../store/action/user/user';

import { loadCompanies, loadCompany, loadUser } from '../services/user';
import { loadConnectionList } from '../services/connection';
import { loadBonuses } from '../services/settings/bonuses';
import { checkSbpStatus, loadOkveds } from '../services/settings/general';
import { loadPointsOfSale } from '../services/points-of-sale';
// import { loadEmployers } from '../services/settings/employers';

import { PAGES } from '../utils/constants/shared';

const Authorization = (WrappedComponent) => {
  const WithAuthorization = (props) => {
    const dispatch = useDispatch();
    const router = useRouter();

    const isLoading = useSelector((state) => state.authorization.isLoading);
    const isAuthorized = useSelector(
      (state) => state.authorization.isAuthorized,
    );
    // const sessionToken = useSelector(
    //   (state) => state.authorization.sessionToken,
    // );
    const pathBeforeAuth = useSelector((state) => state.user.pathBeforeAuth);
    const isInitialDataLoading = useSelector(
      (state) => state.user.isInitialDataLoading,
    );
    const companies = useSelector((state) => state.user.companies);
    const company = useSelector((state) => state.user.company);

    useEffect(() => {
      dispatch(ActionCreator.checkAuth());
    }, []);

    useEffect(() => {
      if (isAuthorized) {
        dispatch(loadCompanies());
      }
    }, [isAuthorized]);

    useEffect(() => {
      if (isAuthorized) {
        if (companies.length !== 0) {
          Promise.all([
            dispatch(loadCompany()),
            dispatch(loadConnectionList()),
            dispatch(loadUser()),
            // dispatch(loadEmployers({ limit: 0 })),
            dispatch(loadBonuses()),
            dispatch(checkSbpStatus()),
            dispatch(loadOkveds()),
            dispatch(loadPointsOfSale({ limit: 0 })),
          ])
            .then(() => {
              dispatch(UserActionCreator.setIsInitialDataLoading(false));
            })
            .catch(() => {
              dispatch(UserActionCreator.setIsInitialDataLoading(false));
            });
        } else {
          dispatch(loadUser());
          dispatch(UserActionCreator.setIsInitialDataLoading(false));
        }
      }
    }, [companies]);

    // React.Fragment для возвращения пустой страницы
    if (isLoading) {
      return <></>;
    }

    // if (!isAuthorized && sessionToken && router.pathname !== PAGES.checkCode.route) {
    //   router.push(PAGES.checkCode.route);

    //   return <></>;
    // }

    // Если не авторизован и стрнаица не /login, то перенаправляет на страницу авторизации
    if (!isAuthorized && router.pathname !== PAGES.login.route) {
      // Записываем страницу на которую пользователь хотел попасть, чтобы после успешной авторизации перенаправить на эту страницу
      dispatch(UserActionCreator.setPathBeforeAuth(router.pathname));
      router.push(PAGES.login.route);

      return <></>;
    }

    const getRoute = () => {
      if (company.status === 0) {
        router.push(PAGES.connection.route);
      } else if (pathBeforeAuth && pathBeforeAuth !== PAGES.logout.route) {
        if (pathBeforeAuth === PAGES.registrationCompany.route) {
          router.push(PAGES.dashboard.route);
        } else {
          router.push(pathBeforeAuth);
        }
      } else {
        router.push(PAGES.dashboard.route);
      }
    };

    // Если авторизован и стрнаица /login, то перенаправляет на pathBeforeAuth, иначе на /dashboard
    if (
      isAuthorized
      && router.pathname === PAGES.login.route
      && !isInitialDataLoading
    ) {
      if (companies.length === 0) {
        router.push(PAGES.registrationCompany.route);
        dispatch(UserActionCreator.setIsInitialDataLoading(true));
      } else {
        getRoute();
      }
    }

    if (
      router.pathname === PAGES.registrationCompany.route
      && companies.length !== 0
      && !isInitialDataLoading
    ) {
      getRoute();
    }

    return <WrappedComponent {...props} />;
  };

  return WithAuthorization;
};

export default Authorization;
