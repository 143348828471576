import { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { message } from 'antd';
import PropTypes from 'prop-types';

import FormField from '../form-field/form-field';
import { getNestedObjectFieldValue } from '../../../utils/helpers/shared';

// import styles from './react-async-type-ahead.module.scss';

const ReactAsyncTypeAhead = forwardRef(
  (
    {
      formik,
      form,
      options,
      field,
      labelKey,
      placeholder,
      service,
      errorText,
      formItemClassName,
      dependentFields,
      ...props
    },
    ref,
  ) => {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const loadListWithSearch = (searchString) => {
      const body = { limit: 0 };

      if (searchString) {
        body.search = searchString;
      }

      setIsLoading(true);

      dispatch(service(body))
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          message.error(errorText);
          setIsLoading(false);
        });
    };

    return (
      <FormField
        ref={ref}
        formik={formik}
        name={field}
        id={field}
        inputProps={{
          id: field,
        }}
        labelKey={labelKey}
        minLength={0}
        placeholder={placeholder}
        component={AsyncTypeahead}
        onSearch={(value) => {
          if (value) {
            loadListWithSearch(value);
          }
        }}
        onInputChange={(value) => {
          if (!value) {
            loadListWithSearch();
          }
        }}
        onChange={(values) => {
          if (values.length) {
            formik.setFieldValue(field, values);
          }
          if (dependentFields) {
            dependentFields.forEach(async (elem) => {
              await formik.setFieldValue(
                elem.name,
                values && values[0]
                  ? getNestedObjectFieldValue(values[0], elem.pathParts)
                  : elem.emptyValue,
              );
              await form.setFieldsValue({
                [elem.name]:
                  values && values[0]
                    ? getNestedObjectFieldValue(values[0], elem.pathParts)
                    : elem.emptyValue,
              });
              formik.setFieldTouched(elem.name);
              // formik.validateField(elem.name);
            });
          }
        }}
        onBlur={(evt) => {
          formik.setFieldTouched([field], true);
          if (
            !formik.values[field]
            || (formik.values[field]
              && formik.values[field][0]
              && evt.target.value !== formik.values[field][0].name)
          ) {
            formik.setFieldValue(field, null);
            form.resetFields([field]);
            loadListWithSearch();
          }
        }}
        formItemClassName={formItemClassName}
        options={options}
        allowNew={false}
        isLoading={isLoading}
        emptyLabel="Ничего не найдено"
        searchText="Поиск..."
        {...props}
      />
    );
  },
);

ReactAsyncTypeAhead.propTypes = {
  formik: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  labelKey: PropTypes.string,
  field: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  service: PropTypes.func.isRequired,
  errorText: PropTypes.string.isRequired,
  dependentFields: PropTypes.arrayOf(PropTypes.shape()),
  formItemClassName: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
};

ReactAsyncTypeAhead.defaultProps = {
  labelKey: 'name',
  dependentFields: null,
  formItemClassName: null,
};

export default ReactAsyncTypeAhead;
