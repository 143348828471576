import humps from 'humps';
import { Table } from 'antd';
import PropTypes from 'prop-types';
// import styles from './data-table.module.scss';

const DataTable = ({
  params,
  setParams,
  setCurrentTablePage,
  loading,
  data,
  columns,
  pagination,
  expandable,
  scroll,
  size,
  getRowKey,
}) => {
  const handleTableParametersChange = (currentPagination, filters, sorter) => {
    const newParams = {
      limit: currentPagination.pageSize,
      offset: (currentPagination.current - 1) * currentPagination.pageSize,
    };

    switch (sorter.order) {
      case 'ascend':
        newParams.orderBy = humps.decamelize(sorter.field);
        break;
      case 'descend':
        newParams.orderBy = humps.decamelize(`-${sorter.field}`);
        break;
      default:
        newParams.orderBy = '';
        break;
    }

    setParams({
      ...params,
      ...newParams,
    });

    if (setCurrentTablePage) {
      setCurrentTablePage(currentPagination.current);
    }
  };

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey={(item) => (getRowKey ? getRowKey(item) : item.id)}
      pagination={
        pagination
          ? {
            size: 'small',
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50],
            ...pagination,
          }
          : false
      }
      expandable={expandable}
      onChange={handleTableParametersChange}
      loading={loading}
      className="table"
      size={size}
      scroll={scroll}
    />
  );
};

DataTable.propTypes = {
  params: PropTypes.shape().isRequired,
  setParams: PropTypes.func.isRequired,
  setCurrentTablePage: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pagination: PropTypes.shape(),
  expandable: PropTypes.shape(),
  scroll: PropTypes.shape(),
  size: PropTypes.string,
  getRowKey: PropTypes.func,
};

DataTable.defaultProps = {
  setCurrentTablePage: null,
  data: null,
  expandable: null,
  pagination: null,
  scroll: { x: 400 },
  size: 'default',
  getRowKey: null,
};

export default DataTable;
