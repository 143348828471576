export const ActionType = {
  SET_ACTIVITY: 'SET_ACTIVITY',
  SET_BALANCE: 'SET_BALANCE',
  SET_CUSTOMERS_TOP: 'SET_CUSTOMERS_TOP',
  SET_PROFIT: 'SET_PROFIT',
  SET_SUMMARY: 'SET_SUMMARY',
};

export const ActionCreator = {
  setActivity: (data) => ({
    type: ActionType.SET_ACTIVITY,
    payload: data,
  }),
  setBalance: (data) => ({
    type: ActionType.SET_BALANCE,
    payload: data,
  }),
  setCustomersTop: (data) => ({
    type: ActionType.SET_CUSTOMERS_TOP,
    payload: data,
  }),
  setProfit: (data) => ({
    type: ActionType.SET_PROFIT,
    payload: data,
  }),
  setSummary: (data) => ({
    type: ActionType.SET_SUMMARY,
    payload: data,
  }),
};
