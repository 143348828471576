import { ActionType } from '../../action/settings/employers';

const initialState = {
  employers: [],
  employerDetails: null,
  roles: [],
  availableTree: [],
};

const setEmployers = (state, data) => ({
  ...state,
  employers: data,
});

const setEmployerDetails = (state, data) => ({
  ...state,
  employerDetails: data,
});

const setRoles = (state, data) => ({
  ...state,
  roles: data,
});

const setAvailableTree = (state, data) => ({
  ...state,
  availableTree: data,
});

const employers = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_EMPLOYERS:
      return setEmployers(state, action.payload);

    case ActionType.SET_EMPLOYER_DETAILS:
      return setEmployerDetails(state, action.payload);

    case ActionType.SET_ROLES:
      return setRoles(state, action.payload);

    case ActionType.SET_AVAILABLE_TREE:
      return setAvailableTree(state, action.payload);

    default:
      return state;
  }
};

export default employers;
