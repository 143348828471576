import { useDispatch } from 'react-redux';
import {
  Button, message, Modal, Space,
} from 'antd';
import { CopyFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { loadUser } from '../../../../services/user';

import styles from './modal-add-telegram.module.scss';

const ModalAddTelegram = ({ telegramInfo, setTelegramInfo, onClose }) => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(loadUser());
    setTelegramInfo('');
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      title="Привязать Телеграм-чат"
      open={telegramInfo}
      destroyOnClose
      footer={null}
      onCancel={handleCancel}
    >
      <>
        <Space direction="vertical" align="center" size="small">
          <p>
            Ссылка для привязки
            <Button
              type="link"
              title="Скопировать в буфер обмена"
              onClick={() => {
                navigator.clipboard
                  .writeText(telegramInfo)
                  .catch((err) => message.error(
                    `Ваш браузер не поддерживает данную функцию, ${err}`,
                  ));
              }}
            >
              <CopyFilled className={styles['link-icon']} />
            </Button>
          </p>
          <a target="_blank" rel="noopener noreferrer" href={telegramInfo}>
            {telegramInfo}
          </a>
          <img
            src={`https://qr.invoice.su/getQR?id=${telegramInfo}`}
            alt="QR-code"
            width="100%"
          />
        </Space>
      </>
    </Modal>
  );
};

ModalAddTelegram.propTypes = {
  telegramInfo: PropTypes.string.isRequired,
  setTelegramInfo: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

ModalAddTelegram.defaultProps = {
  onClose: null,
};

export default ModalAddTelegram;
