import { Space } from 'antd';
import PropTypes from 'prop-types';

import FormField from '../form-field/form-field';

import styles from './filters-input-group.module.scss';

const FiltersInputGroup = ({
  formik,
  label,
  name,
  from,
  to,
}) => (
  <Space
    className={`${styles['form__item-group']} ${styles['filter__field-wrapper']}`}
    size={0}
    align="start"
  >
    <FormField
      formik={formik}
      label={label}
      name={`${name}${from}`}
      placeholder="Минимум"
      allowClear
      className={styles.filter__input}
    />
    <FormField
      formik={formik}
      label=" "
      name={`${name}${to}`}
      placeholder="Максимум"
      allowClear
      className={styles.filter__input}
    />
  </Space>
);

FiltersInputGroup.propTypes = {
  formik: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default FiltersInputGroup;
