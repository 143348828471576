import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Form, Input, message, Modal, Space,
} from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';

import { FormField } from '../../../shared';

import {
  create2FACodes,
  load2FACodesList,
} from '../../../../services/settings/general';

import styles from './modal-2fa-codes.module.scss';

const Modal2FACodes = ({ codes2FAModalVisible, setCodes2FAModalVisible }) => {
  const [isSending, setIsSending] = useState(false);
  const [step, setStep] = useState(codes2FAModalVisible);

  const codes2FA = useSelector((state) => state.general.codes2FA);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const initialValues = useMemo(
    () => ({
      password: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    password: Yup.string().required('Поле обязательно для заполнения'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setIsSending(true);
      dispatch(load2FACodesList(values))
        .then(() => {
          setIsSending(false);
          setStep(2);
        })
        .catch((e) => {
          setIsSending(false);

          const err = e.response.data.detail || e.response.data.non_field_errors[0];

          if (err) {
            message.error(err);
          } else {
            message.error('Произошла ошибка');
          }
        });
    },
  });

  const handleCancel = () => {
    setCodes2FAModalVisible();
    formik.resetForm();
    form.resetFields();
  };

  const refreshCodes = () => {
    setIsSending(true);
    dispatch(create2FACodes(formik.values))
      .then(() => {
        setIsSending(false);
      })
      .catch((e) => {
        setIsSending(false);

        const err = e.response.data.detail;

        if (err) {
          message.error(err);
        } else {
          message.error('Произошла ошибка');
        }
      });
  };

  const saveCodes = () => {
    const currentCodes = codes2FA
      .slice()
      .map((item, index) => `${index + 1}) ${item}`);

    currentCodes.unshift('Коды восстановления доступа:', '');
    currentCodes.push(
      '',
      'ВАЖНО: Код становится недействительным после использования.',
    );

    const fileContent = currentCodes.join('\r\n');

    const blob = new Blob([fileContent], {
      type: 'text/plain;charset=utf-8',
    });
    FileSaver.saveAs(blob, 'codes.txt');
  };

  return (
    <Modal
      title="Коды восстановления доступа"
      open={codes2FAModalVisible}
      destroyOnClose
      footer={null}
      onCancel={handleCancel}
    >
      <>
        {step === 1 && (
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...formik.values,
            }}
            onFinish={formik.handleSubmit}
            className={styles['modal-2fa-codes__form']}
          >
            <FormField
              formik={formik}
              label="Текущий пароль"
              name="password"
              placeholder="Введите текущий пароль"
              component={Input.Password}
              prefix={<LockOutlined />}
            />
            <Button disabled={isSending} type="primary" htmlType="submit">
              Запросить коды
            </Button>
          </Form>
        )}

        {step === 2 && (
          <>
            <h3 className={styles['modal-2fa-codes__title']}>
              Текущий перечень кодов восстановления доступа:
            </h3>
            <ul>
              {codes2FA.map((code) => (
                <li key={code}>
                  {code}
                </li>
              ))}
            </ul>
            <Space>
              <Button type="primary" onClick={refreshCodes}>
                Обновить коды
              </Button>
              <Button
                type="primary"
                title="Скопировать в буфер обмена"
                onClick={() => {
                  navigator.clipboard
                    .writeText(codes2FA)
                    .catch((err) => message.error(
                      `Ваш браузер не поддерживает данную функцию, ${err}`,
                    ));
                }}
              >
                Скопировать коды
              </Button>
              <Button type="primary" onClick={saveCodes}>
                Сохранить коды
              </Button>
            </Space>
          </>
        )}
      </>
    </Modal>
  );
};

Modal2FACodes.propTypes = {
  codes2FAModalVisible: PropTypes.bool.isRequired,
  setCodes2FAModalVisible: PropTypes.func.isRequired,
};

Modal2FACodes.defaultProps = {};

export default Modal2FACodes;
