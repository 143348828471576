import { Radio, Space } from 'antd';
import PropTypes from 'prop-types';

import RangePickerItem from '../range-picker-item/range-picker-item';

import { getPeriod } from '../../../utils/helpers/shared';
import { periods } from '../../../utils/constants/shared';
import { periodRangePickersNames } from '../../../utils/constants/dashboard';

import styles from './date-filter.module.scss';

const DateFilter = ({ formik }) => {
  const onChangeFilter = (e) => formik.setFieldValue(
    'period',
    getPeriod(periods[e.target.value].value, periods[e.target.value].unit),
  );

  return (
    <Space className={styles['date-filter']}>
      <div className={styles['date-filter__radio']}>
        <Radio.Group onChange={onChangeFilter} defaultValue="month" buttonStyle="outline">
          {Object.keys(periods).map((key) => (
            <Radio.Button value={key} key={key}>{periods[key].title}</Radio.Button>
          ))}
        </Radio.Group>
      </div>

      <RangePickerItem
        formik={formik}
        field="period"
        fields={periodRangePickersNames}
        currentClass={styles['date-filter__range-picker']}
      />
    </Space>
  );
};

DateFilter.propTypes = {
  formik: PropTypes.shape().isRequired,
};

export default DateFilter;
