import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge, Dropdown, Menu, Space,
} from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

import ModalAddTelegram from '../modals/modal-add-telegram/modal-add-telegram';
import ModalChangeName from '../modals/modal-change-name/modal-change-name';
import ModalChangePassword from '../modals/modal-change-password/modal-change-password';
import ModalActivate2FA from '../modals/modal-activate-2fa/modal-activate-2fa';
import ModalDeactivate2FA from '../modals/modal-deactivate-2fa/modal-deactivate-2fa';
import Modal2FACodes from '../modals/modal-2fa-restore-codes/modal-2fa-codes';
import { LinkTo } from '../../shared';

import { loadTelegramInfo } from '../../../services/user';

import { ActionCreator } from '../../../store/action/authorization/authorization';

import styles from './user.module.scss';

const User = () => {
  const user = useSelector((state) => state.user.user);

  const [telegramInfo, setTelegramInfo] = useState('');
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
  const [changeNameModalVisible, setChangeNameModalVisible] = useState(false);
  const [activate2FAModalVisible, setActivate2FAModalVisible] = useState(false);
  const [deactivate2FAModalVisible, setDeactivate2FAModalVisible] = useState(false);
  const [codes2FAModalVisible, setCodes2FAModalVisible] = useState();

  const dispatch = useDispatch();

  const {
    // logo, name, phone, telegram, enabledTwoFactorAuth,
    name, phone, telegram, enabledTwoFactorAuth,
  } = user;

  const getTelegramChatInfo = () => {
    dispatch(loadTelegramInfo()).then((data) => {
      setTelegramInfo(data.data.link);
    });
  };

  const openModalAddTelegram = () => {
    setTelegramInfo(telegram.link);
  };

  const openModalChangeName = () => {
    setChangeNameModalVisible(true);
  };

  const openModalChangePassword = () => {
    setChangePasswordModalVisible(true);
  };

  const openModalActivate2FA = () => {
    setActivate2FAModalVisible(true);
  };

  const openModalDeactivate2FA = () => {
    setDeactivate2FAModalVisible(true);
  };

  const openModal2FACodes = () => {
    setCodes2FAModalVisible(1);
  };

  const handleLogoutLinkClick = () => {
    dispatch(ActionCreator.resetStore());
    dispatch(ActionCreator.logout());
  };

  useEffect(() => {
    if (Object.keys(user).length && !name) {
      setChangeNameModalVisible(true);
    }
  }, [user]);

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={openModalChangeName}>
        Смена имени
      </Menu.Item>
      <Menu.Item key="1" onClick={openModalChangePassword}>
        Смена пароля
      </Menu.Item>
      {telegram?.status === 0 && (
        <Menu.Item key="2" onClick={getTelegramChatInfo}>
          <Badge dot>Привязать телеграм</Badge>
        </Menu.Item>
      )}
      {telegram?.status === 1 && (
        <Menu.Item key="2" onClick={openModalAddTelegram}>
          <Badge dot>Привязать телеграм</Badge>
        </Menu.Item>
      )}
      {telegram?.status === 2 && (
        <Menu.Item key="2">
          <a target="_blank" rel="noopener noreferrer" href={telegram.link}>
            Телеграм привязан
          </a>
        </Menu.Item>
      )}
      {!enabledTwoFactorAuth && (
        <Menu.Item key="3" onClick={openModalActivate2FA}>
          Активировать 2FA
        </Menu.Item>
      )}
      {enabledTwoFactorAuth && (
        <>
          <Menu.Item key="3" onClick={openModalDeactivate2FA}>
            Деактивировать 2FA
          </Menu.Item>
          <Menu.Item key="4" onClick={openModal2FACodes}>
            Коды восстановления 2FA
          </Menu.Item>
        </>
      )}
      <Menu.Item key="5">
        <LinkTo href="/" onClick={handleLogoutLinkClick}>
          Выйти
        </LinkTo>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
        <Space className={styles.user__container}>
          {/* <Avatar src={logo} size={30} /> */}
          <UserOutlined />
          <Badge dot={telegram?.status !== 2}>
            <span>{name || phone}</span>
          </Badge>
          <DownOutlined />
        </Space>
      </Dropdown>

      <ModalAddTelegram
        telegramInfo={telegramInfo}
        setTelegramInfo={setTelegramInfo}
      />

      {changeNameModalVisible && (
        <ModalChangeName
          changeNameModalVisible={changeNameModalVisible}
          setChangeNameModalVisible={setChangeNameModalVisible}
        />
      )}

      {changePasswordModalVisible && (
        <ModalChangePassword
          changePasswordModalVisible={changePasswordModalVisible}
          setChangePasswordModalVisible={setChangePasswordModalVisible}
        />
      )}

      {activate2FAModalVisible && (
        <ModalActivate2FA
          activate2FAModalVisible={activate2FAModalVisible}
          setActivate2FAModalVisible={setActivate2FAModalVisible}
          setCodes2FAModalVisible={setCodes2FAModalVisible}
        />
      )}

      {deactivate2FAModalVisible && (
        <ModalDeactivate2FA
          deactivate2FAModalVisible={deactivate2FAModalVisible}
          setDeactivate2FAModalVisible={setDeactivate2FAModalVisible}
        />
      )}

      {codes2FAModalVisible && (
        <Modal2FACodes
          codes2FAModalVisible={codes2FAModalVisible}
          setCodes2FAModalVisible={setCodes2FAModalVisible}
        />
      )}
    </>
  );
};

export default User;
