// export const HOST_BASE = 'backend.invoice.su';
// export const URL_BASE = `https://${HOST_BASE}/api`;

const URL_BASE = process.env.NEXT_PUBLIC_URL_BASE || 'https://lk.backend.ofd-test.invoice.su';
const URL_UPLOADER = process.env.NEXT_PUBLIC_URL_UPLOADER || 'https://uploader.backend.ofd-test.invoice.su';
const URL_PAYMENT_PAGE = process.env.NEXT_PUBLIC_URL_PAYMENT_PAGE || 'https://pay.invoice.su';
const REQUEST_TIMEOUT = process.env.NEXT_PUBLIC_REQUEST_TIMEOUT || 10000;
const DADATA_TOKEN = process.env.NEXT_PUBLIC_DADATA_TOKEN || '951877a6c80c7c7e155ec7fbb785c94a4f58f594';
const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || '';
const PAYMENT_ORIGIN = process.env.NEXT_PUBLIC_PAYMENT_ORIGIN || 'http://localhost:3000';
const URL_BUSINESS_CARD_BIND = process.env.NEXT_PUBLIC_URL_BUSINESS_CARD_BIND || `${PAYMENT_ORIGIN}/rosbank/payouts/bind/`;
const URL_CARD_PAYOUT_CREATE = process.env.NEXT_PUBLIC_URL_CARD_PAYOUT_CREATE || `${PAYMENT_ORIGIN}/rosbank/payouts/b2c/`;
const URL_CARD_PAYOUT_STATUS = process.env.NEXT_PUBLIC_URL_CARD_PAYOUT_STATUS || `${PAYMENT_ORIGIN}/rosbank/payouts/b2c/status/`;

export {
  URL_BASE,
  URL_UPLOADER,
  URL_PAYMENT_PAGE,
  REQUEST_TIMEOUT,
  DADATA_TOKEN,
  RECAPTCHA_SITE_KEY,
  PAYMENT_ORIGIN,
  URL_BUSINESS_CARD_BIND,
  URL_CARD_PAYOUT_CREATE,
  URL_CARD_PAYOUT_STATUS,
};
