import humps from 'humps';
import { message } from 'antd';
import { ActionCreator } from '../store/action/connection/connection';

export const loadConnectionList = () => (dispatch, _getState, api) => api
  .post('/connection/list')
  .then(({ data }) => {
    dispatch(ActionCreator.setConnectionList(humps.camelizeKeys(data)));

    return humps.camelizeKeys(data);
  })
  .catch(() => {
    message.error('Не удалось загрузить информацию о подключении');
  });

export const sendIntegrationConfirmation = () => (dispatch, _getState, api) => api.post('/connection/perform/integration');
