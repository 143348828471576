import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button, Form, message, Popconfirm,
} from 'antd';
import { useFormik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';

import ModalWrapper from '../modal-wrapper/modal-wrapper';

import { loadConnectionList } from '../../../services/connection';

import styles from './modal-edit-or-create-item.module.scss';

const ModalEditOrCreateItem = ({
  params,
  item,
  setItem,
  children,
  createItem,
  updateItem,
  deleteItem,
  loadList,
  formikProps,
  modalClassName,
  modalTitle,
  initialExtraAction,
  successExtraAction,
  failExtraAction,
}) => {
  const [isSending, setIsSending] = useState(false);

  const isOpen = !!item;

  const currentItem = cloneDeep(item);

  const itemId = currentItem ? currentItem.id : null;
  const close = () => setItem('');

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const formik = useFormik({
    initialValues: {
      ...currentItem,
    },
    onSubmit: (values) => {
      setIsSending(true);

      const func = (v) => (itemId ? updateItem(v) : createItem(v));

      dispatch(func(values))
        .then(({ data }) => {
          setIsSending(false);
          message.success(itemId ? 'Изменено' : 'Добавлено');
          dispatch(loadList(params)).catch(() => {
            message.error('Не удалось загрузить перечень');
          });
          dispatch(loadConnectionList()).catch(() => {
            message.error('Не удалось загрузить статус компании');
          });

          if (successExtraAction) {
            successExtraAction(data);
          }

          close();
        })
        .catch((err) => {
          setIsSending(false);
          message.error(
            itemId ? 'Не удалось обновить информацию' : 'Не удалось добавить',
          );

          if (failExtraAction) {
            failExtraAction(err);
          }
        });
    },
    ...formikProps,
  });

  useEffect(() => {
    if (item) {
      formik.setValues(currentItem);
      form.setFields(
        Object.keys(currentItem).map((i) => ({
          name: i,
          value: currentItem[i],
        })),
      );

      if (initialExtraAction) {
        initialExtraAction(formik, form);
      }
    }
  }, [item]);

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
      form.resetFields();
    }
  }, [isOpen]);

  const handleItemDelete = () => {
    setIsSending(true);
    dispatch(deleteItem({ id: itemId }))
      .then(() => {
        setIsSending(false);
        message.success('Удалено');
        dispatch(loadList(params)).catch(() => {
          message.error('Не удалось загрузить перечень');
        });
        close();
      })
      .catch(() => {
        setIsSending(false);
        message.error('Не удалось удалить');
      });
  };

  let title;

  if (modalTitle) {
    title = modalTitle;
  } else {
    title = isOpen && itemId ? 'Редактировать' : 'Добавить';
  }

  return (
    <ModalWrapper
      title={title}
      open={isOpen}
      onCancel={close}
      getContainer={false}
      className={modalClassName}
    >
      {(setIsFormFieldsChanged) => (
        <Form
          form={form}
          layout="vertical"
          onFinish={formik.handleSubmit}
          onFieldsChange={() => setIsFormFieldsChanged(true)}
        >
          {children(formik, form)}
          <div className={styles['form-controls__wrapper']}>
            <Form.Item>
              <Button
                // disabled={!formik.isValid || isSending}
                disabled={isSending}
                type="primary"
                htmlType="submit"
              >
                {itemId ? 'Изменить' : 'Создать'}
              </Button>
            </Form.Item>
            {itemId && deleteItem && (
              <Form.Item>
                <Popconfirm
                  title="Действительно удалить? Действие безвозвратно"
                  onConfirm={handleItemDelete}
                  onCancel={() => {}}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button disabled={isSending} type="danger">
                    Удалить
                  </Button>
                </Popconfirm>
              </Form.Item>
            )}
          </div>
        </Form>
      )}
    </ModalWrapper>
  );
};

ModalEditOrCreateItem.propTypes = {
  params: PropTypes.shape(),
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  setItem: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  createItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func,
  deleteItem: PropTypes.func,
  loadList: PropTypes.func,
  formikProps: PropTypes.shape(),
  modalClassName: PropTypes.string,
  modalTitle: PropTypes.string,
  initialExtraAction: PropTypes.func,
  successExtraAction: PropTypes.func,
  failExtraAction: PropTypes.func,
};

ModalEditOrCreateItem.defaultProps = {
  params: null,
  item: null,
  updateItem: null,
  deleteItem: null,
  loadList: null,
  formikProps: null,
  modalClassName: null,
  modalTitle: null,
  initialExtraAction: null,
  successExtraAction: null,
  failExtraAction: null,
};

export default ModalEditOrCreateItem;
