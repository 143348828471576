import { PartySuggestions } from 'react-dadata';
import ReactInputMask from 'react-input-mask';

import phoneNumberFormatter from '../../formatters/_phone-field-formatter';

import { DADATA_TOKEN } from './_base';

const addCompanyModalFields = [
  {
    label: 'Юридическое лицо',
    name: 'inn',
    token: DADATA_TOKEN,
    component: PartySuggestions,
    inputProps: {
      className: 'ant-input',
      placeholder: 'Введите ИНН или название',
      name: 'inn',
    },
    count: 5,
    pathParts: ['data', 'inn'],
    dependentFields: [
      {
        name: 'name',
        pathParts: ['value'],
      },
      {
        name: 'ogrn',
        pathParts: ['data', 'ogrn'],
      },
    ],
  },
  {
    label: 'Название организации',
    name: 'name',
    placeholder: 'Введите название организации',
    allowClear: true,
  },
  {
    name: 'ogrn',
    hidden: true,
  },
  {
    label: 'Номер телефона',
    name: 'phone',
    placeholder: 'Введите номер телефона',
    component: ReactInputMask,
    mask: '+7\\-(999)-999-99-99',
    maskChar: 'X',
    formatter: phoneNumberFormatter,
  },
];

export default addCompanyModalFields;
